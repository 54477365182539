
import Vue, { VueConstructor } from "vue";

import BasisWeeklyHistoryChart from "../charts/BasisWeeklyHistoryChart.vue";
import BasisWeeklyHistoryTable from "../tables/BasisWeeklyHistoryTable.vue";

import CrudMixins from "@/mixins/crud";
import Growing from "@/models/growing";
import Ownership from "@/models/ownership";
import City from "@/models/city";
import BasisShipment from "@/models/basisShipment";

import { BasisWeeklyHistory } from "@/business/HistoryBasis";
import { mapGetters, mapActions } from "vuex";
import { basisService } from "@/services/basis";
import { IBasisHistoryParams } from "@/types/service-types";
import { QuotationsDate } from "@/business/Quotations";
import User from "@/models/user";

interface IChartComponent {
  generateChart?: () => void;
}

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CrudMixins>>
).extend({
  props: {
    selectedGrowing: Growing,
    ownerSelected: Ownership,
    basisCity: String,
    basisAsset: String,
    basisShipments: Array as () => BasisShipment[],
    shipmentsQuotes: QuotationsDate,
    userLogged: User,
  },
  components: {
    WeekTable: BasisWeeklyHistoryTable,
    WeekChart: BasisWeeklyHistoryChart,
  },
  mixins: [CrudMixins],

  data() {
    return {
      // Cidades que temos dados do basis
      basisWeeklyHistory: new BasisWeeklyHistory(),
      // controla o skelleton
      loading: false,
      // Controla msg de erro
      error: false,
      // Monstra no grafico
      errorMessage: "",
    };
  },

  methods: {
    ...mapActions({
      myOwnerships: "MY_OWNERSHIPS",
    }),
    async loadHistoryTableChart(): Promise<void> {
      this.loading = true;
      await this.getBasisWeeklyHistory();
      this.loading = false;
      this.$nextTick(() => {
        this.chartGeneration();
      });
    },
    // GET BASIS DATA
    async getBasisWeeklyHistory(): Promise<void> {
      this.error = false;
      try {
        if (this.selectedGrowing?.symbol && this.basisCity) {
          let params: IBasisHistoryParams = {
            growing: this.selectedGrowing?.symbol,
            city: this.basisCity.split(" - ")[0],
            state: this.basisCity.split(" - ")[1],
            period: "weekly",
            se: this.basisAsset.toUpperCase(),
          };

          this.basisWeeklyHistory = await basisService.getBasisWeeklyHistory(
            params
          );

        } else {
          let missing = [];
          if (!this.selectedGrowing) missing.push("Cultura");
          if (!this.basisCity) {
            missing.push("'Cidade Referência'");
            let ownerCity = this.cities.find(
              (c: City) => this.ownerSelected?.city_id == c.id
            );
            let ownerRefCity = this.cities.find(
              (c: City) => this.ownerSelected?.city_reference_id == c.id
            );
            if (this.selectedGrowing && ownerCity && ownerRefCity) {
              ownerCity = ownerCity.name;
              ownerRefCity = ownerRefCity.name;
              this.errorMessage = `Não há dados historicos para ${this.selectedGrowing.name} na cidade ${ownerCity}/${ownerRefCity}`;
            } else {
              this.errorMessage = "Nenhuma Cultura/Propriedade encontra";
            }
          }
          throw new Error("missing args " + missing.join(" ,"));
        }
      } catch (error) {
        console.error(error);
        this.error = true;
      }
    },

    chartGeneration(): void {
      const execChart = this.$refs[
        "BasisWeeklyHistoryChart"
      ] as IChartComponent;
      if (execChart && execChart.generateChart) {
        execChart.generateChart();
      }
    },
    startLoadComp(): void {
      this.loading = true;
    },
  },

  computed: {
    ...mapGetters({
      growings: "getGrowings",
      cities: "getCities",
    }),
    resourceFilteredByGrowing(): Array<BasisShipment> {
      return this.results.objects.filter((r: BasisShipment) => {
        return r.growing_id === this.selectedGrowing?.id;
      });
    },
  },
});
