
import Vue, { VueConstructor } from "vue";

import Currency from "@/models/currency";
import Growing from "@/models/growing";
import Ownership from "@/models/ownership";

import constants from "@/constants";
import { getMaxYaxis, getMinYaxis } from "@/utilsText";
import logoImage from "@/assets/img/agrinvest_logo.png";

import { QuotationsDate, Quotes } from "@/business/Quotations";
import {
  BasisMonthlyHistory,
  DisplayBasisMonthlyHistory,
  IBasisChartOptions,
} from "@/business/HistoryBasis";

type SackPrice = {
  rs: number;
  us: number;
};

export default (Vue as VueConstructor<Vue>).extend({
  props: {
    growingSelected: Growing,
    ownershipSelected: Ownership,
    basisMonthlyHistory: BasisMonthlyHistory,
    basisCity: String,
    quotesDates: QuotationsDate,
    basisAsset: String,
    loading: Boolean,
    datesRange: Object,
    yearSelected: Number,
  },
  data: () => ({
    options: undefined as IBasisChartOptions | undefined,
    currency: undefined as Currency | undefined,
    show: false,
    crudSkelletonAttrs: {
      class: "mb-6",
      elevation: 0,
    },
    crudClass:
      "font-weight-bold font-weight-black caption text-md-h5 text-sm-subtitle-1",
    colors: ["#066F6A", "#D1AE6C", "#F26B29"],
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
  }),
  methods: {
    calcSackPrice(dollar: number, se: number, basis: number): SackPrice {
      let rs_sc = 0;
      let us_sc = 0;

      let constant = constants.get(this.growingSelected.symbol);

      if (this.basisAsset === "cbot") {
        if (constant) {
          rs_sc = ((basis + se) * constant.x * dollar) / 100;
          us_sc = ((basis + se) * constant.x) / 100;
        }
      } else {
        rs_sc = basis + se;
        us_sc = (basis + se) / dollar;
      }
      return {
        rs: rs_sc,
        us: us_sc,
      } as SackPrice;
    },
    monthlyHistoryItems(): DisplayBasisMonthlyHistory[] {
      let basis = 0;
      let se = 0;
      let dollar = 0;
      let code_se = "";
      let code_dollar = "";
      let sc_price = {} as SackPrice;
      let seArray = new Quotes();

      let dates = this.datesRange[this.yearSelected];
      let datesAvailable = dates ? [...dates] : [];
      if (this.yearSelected === new Date().getFullYear()) {
        datesAvailable.shift();
      }
      return datesAvailable.map((el) => {
        let dateCompare = this.$luxon(el)
          .toString()
          .split("-")
          .slice(0, 2)
          .join("-");

        // add a month in each obj
        const dArray = this.quotesDates.dollar.filter((dollar, idx) => {
          if (dollar.date) {
            // add a month in each obj
            let quoteDate = this.$luxon(dollar.date)
              .toString()
              .split("-")
              .slice(0, 2)
              .join("-");

            if (quoteDate === dateCompare) {
              return this.quotesDates.dollar[idx + 1];
            }
          }
        })[0];
        if (dArray) {
          dollar = dArray.value;
          code_dollar = dArray.code;
        }

        if (this.basisAsset === "cbot") {
          seArray = this.quotesDates.se.cbot.filter((se, idx) => {
            if (se.date) {
              let quoteDate = this.$luxon(se.date)
                .toString()
                .split("-")
                .slice(0, 2)
                .join("-");
              if (quoteDate === dateCompare) {
                return this.quotesDates.se.cbot[idx + 1];
              }
            }
          })[0];
        }
        if (this.basisAsset === "b3") {
          seArray = this.quotesDates.se.b3.filter((se, idx) => {
            if (se.date) {
              let quoteDate = this.$luxon(se.date)
                .toString()
                .split("-")
                .slice(0, 2)
                .join("-");
              if (quoteDate === dateCompare) {
                return this.quotesDates.se.b3[idx + 1];
              }
            }
          })[0];
        }
        if (seArray) {
          se = seArray.value;
          code_se = seArray.code;
        }
        const basisArray = this.basisMonthlyHistory.history.filter((basis) => {
          // add month cz getMonth starts from 0;
          let compareBasis = new Date(el).getMonth() + 1;

          if (compareBasis === parseInt(basis.date)) {
            return basis;
          }
        })[0];
        if (basisArray) basis = basisArray.value;

        sc_price = this.calcSackPrice(dollar, se, basis);
        const month = this.$luxon(el).monthShort.replace(".", "");
        const year = this.$luxon(el).toFormat("yy");
        const _date =
          month.charAt(0).toUpperCase() + month.slice(1) + "-" + year;
        return {
          shipment: _date,
          sc_rs_price: sc_price.rs,
          sc_us_price: sc_price.us,
          se_value: parseFloat(se.toFixed(2)),
          dollar: parseFloat(dollar.toFixed(3)),
          code_se: code_se,
          code_dollar: code_dollar,
          basis: basis,
        } as DisplayBasisMonthlyHistory;
      });
    },
    generateLineChart(): void {
      const colors = this.colors;
      let title = this.basisCity.toUpperCase() + " " + this.basisAsset.toUpperCase();
      let max_rs = 0;
      let max_us = 0;
      let min_rs = 0;
      let min_us = 0;

      const monthlyData = this.monthlyHistoryItems();
      if(Array.isArray(monthlyData) && monthlyData.length) {
        max_rs =
          monthlyData
            .map((e) => e.sc_rs_price)
            .reduce(function (a, b) {
              return Math.max(a, b);
            }) || 0;
        max_us =
          monthlyData
            .map((e) => e.sc_us_price)
            .reduce(function (a, b) {
              return Math.max(a, b);
            }) || 0;
        min_rs =
          monthlyData
            .map((e) => e.sc_rs_price)
            .reduce(function (a, b) {
              return Math.min(a, b);
            }) || 0;
        min_us =
          monthlyData
            .map((e) => e.sc_us_price)
            .reduce(function (a, b) {
              return Math.min(a, b);
            }) || 0;
      }

      const min_rs_yaxes = getMinYaxis(min_rs);
      const max_rs_yaxes = getMaxYaxis(max_rs);

      const min_us_yaxes = Math.floor(min_us) - 1;
      const max_us_yaxes = Math.ceil(max_us) + 1;

      this.options = {
        color: colors,
        title: {
          left: "center",
          text: title,
          textStyle: {
            color: this.$vuetify.theme.dark ? "#fff" : "#000",
            fontSize: 15,
          },
        },
        axisPointer: {
          link: {
            xAxisIndex: "all",
          },
          label: {
            backgroundColor: "#777",
          },
        },
        legend: [
          {
            data: ["R$/sc", "U$/sc"],
            bottom: 15,
            left: "center",
            textStyle: {
              color: this.$vuetify.theme.dark ? "#fff" : "#000",
            },
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
          formatter: (params: any, ticket: any, callback: any) => {
            let prep_body = { 0: "", 1: "" } as any;
            let body = "";
            let header = "";
            let footer = "";
            if (params.length) {
              header = `
                <span style="text-align:center;"> Basis: </span> <br />
                <div class="chart-tooltip">
              `;
              let data_zero = params.filter(
                (el: { seriesIndex: number; data: string }) => {
                  if (el.seriesIndex === 0) {
                    return el.data;
                  }
                }
              )[0].data;
              let data_one = params.filter(
                (el: { seriesIndex: number; data: string }) => {
                  if (el.seriesIndex === 1) {
                    return el.data;
                  }
                }
              )[0].data;
              let name_zero = params.filter(
                (el: { seriesIndex: number; name: string }) => {
                  if (el.seriesIndex === 0) {
                    return el.name;
                  }
                }
              )[0].name;
              let name_one = params.filter(
                (el: { seriesIndex: number; name: string }) => {
                  if (el.seriesIndex === 1) {
                    return el.name;
                  }
                }
              )[0].name;
              for (let i = 0; i < params.length; i++) {
                if (params[i]["seriesIndex"] === 0) {
                  prep_body[0] = `
                    <span style="
                      text-align:left;
                      display:inline-block;
                      margin-right:5px;
                      border-radius:10px;
                      width:10px;
                      height:10px;
                    "></span>
                    <span>
                    <span style="
                      display:inline-block;
                      margin-right:5px;
                      border-radius:10px;
                      width:10px;
                      height:10px;
                      background-color:${this.colors[0]};
                      "></span>${params[0].axisValue}:
                      <br>&emsp;&emsp;
                        &#8702; ${data_zero} ${name_zero}
                    </span> <br />`;
                }
                if (params[i]["seriesIndex"] === 1) {
                  prep_body[1] = `
                    <span style="
                      text-align:left;
                      display:inline-block;
                      margin-right:5px;
                      border-radius:10px;
                      width:10px;
                      height:10px;
                    "></span>
                    <span>
                    <span style="
                      display:inline-block;
                      margin-right:5px;
                      border-radius:10px;
                      width:10px;
                      height:10px;
                      background-color:${this.colors[1]};
                      "></span>${params[1].axisValue}:
                      <br>&emsp;&emsp;
                        &#8702; ${data_one} ${name_one}
                    </span> <br />`;
                }
              }
              for (let key in Object.keys(prep_body).sort()) {
                body += prep_body[key];
              }
              footer += `</div>`;
            }
            return header + body.replace("undefined", "") + footer;
          },
        },
        grid: [
          {
            left: 60,
            right: 50,
            height: "26%",
          },
          {
            left: 60,
            right: 50,
            top: "55%",
            bottom: "25%",
            height: "26%",
          },
        ],
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: monthlyData.map((el) => el.shipment),
            show: false,
          },
          {
            gridIndex: 1,
            type: "category",
            boundaryGap: false,
            data: monthlyData.map((el) => el.shipment),
            axisLabel: {
              textStyle: {
                color: this.$vuetify.theme.dark ? "#fff" : "#000",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "R$/sc",
            position: "left",
            nameLocation: "middle",
            nameGap: 30,
            nameTextStyle: {
              color: this.$vuetify.theme.dark ? "#fff" : "#000",
              fontWeight: "bolder",
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: this.$vuetify.theme.dark ? "#fff" : "#000",
              },
            },
            min: min_rs_yaxes,
            max: max_rs_yaxes,
          },
          {
            type: "value",
            name: "U$/sc",
            position: "left",
            gridIndex: 1,
            nameLocation: "middle",
            nameGap: 30,
            nameTextStyle: {
              color: this.$vuetify.theme.dark ? "#fff" : "#000",
              fontWeight: "bolder",
            },
            axisLine: {
              // show: true,
              lineStyle: {
                color: this.$vuetify.theme.dark ? "#fff" : "#000",
              },
            },
            min: min_us_yaxes,
            max: max_us_yaxes,
          },
        ],
        toolbox: {
          show: true,
          right: "10%",
          feature: {
            saveAsImage: {
              show: true,
              title: "Salvar",
              iconStyle: {
                color: "rgba(255,255,255,0)",
                borderColor: "green",
                borderWidth: 2,
              },
            },
          },
        },
        series: [
          {
            name: "R$/sc",
            type: "line",
            data: monthlyData.map((el) => el.sc_rs_price.toFixed(2)),
            lineStyle: {
              width: 2,
              type: "solid",
            },
            symbol: "circle",
            symbolSize: 10,
          },

          {
            name: "U$/sc",
            type: "line",
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: monthlyData.map((el) => el.sc_us_price.toFixed(2)),
            lineStyle: {
              width: 2,
              type: "solid",
            },
            symbol: "circle",
            symbolSize: 10,
          },
        ],
      };
      this.getWatermark();
    },
    generateChart(): void {
      const colors = this.colors;
      const monthlyData = this.monthlyHistoryItems();
      let title = this.basisCity.toUpperCase() + " " + this.basisAsset.toUpperCase();
      let max_rs = 0;
      let max_us = 0;
      let min_rs = 0;
      let min_us = 0;

      if(Array.isArray(monthlyData) && monthlyData.length) {
        max_rs = monthlyData
          .map((e: { sc_rs_price: any }) => e.sc_rs_price)
          .reduce(function (a: number, b: number) {
            return Math.max(a, b);

          });
        max_us = monthlyData
          .map((e: { sc_us_price: any }) => e.sc_us_price)
          .reduce(function (a: number, b: number) {
            return Math.max(a, b);

          });
        min_rs =
          monthlyData
            .map((e) => e.sc_rs_price)
            .reduce(function (a, b) {
              return Math.min(a, b);

            }) || 0;
        min_us =
          monthlyData
            .map((e) => e.sc_us_price)
            .reduce(function (a, b) {
              return Math.min(a, b);
            }) || 0;
      }

      const rs_digits = Math.floor(Math.log10(Math.abs(max_rs))) + 1;
      const us_digits = Math.floor(Math.log10(Math.abs(max_us))) + 1;

      const max_rs_yaxes = Math.ceil(max_rs) + 5;
      const max_us_yaxes = Math.ceil(max_us) + 1;

      const min_rs_yaxes = Math.floor(min_rs) - 5;
      const min_us_yaxes = Math.floor(min_us) - 2;
      let has_rs_min = false;
      let has_rs_max = false;
      let has_us_min = false;
      let has_us_max = false;

      this.options = {
        color: colors,
        title: {
          left: "center",
          text: title,
          textStyle: {
            color: this.$vuetify.theme.dark ? "#fff" : "#000",
            fontSize: 15,
          },
        },
        axisPointer: {
          link: {
            xAxisIndex: "all",
          },
          label: {
            backgroundColor: "#777",
          },
        },
        legend: [
          {
            data: ["R$/sc", "U$/sc"],
            bottom: 15,
            left: "center",
            textStyle: {
              color: this.$vuetify.theme.dark ? "#fff" : "#000",
            },
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            axis: "x",
          },
          formatter: (params: any, ticket: any, callback: any) => {
            let prep_body = { 0: "", 1: "" } as any;
            let body = "";
            let header = "";
            let footer = "";
            if (params.length) {
              header = `
                <span style="text-align:center;"> Basis: </span> <br />
                <div class="chart-tooltip">
              `;

              let data_zero = "";
              let name_zero = "";
              let color_zero = "";
              let data_one = "";
              let name_one = "";
              let color_one = "";
              params.map((el: any) => {
                if (el.seriesIndex === 0) {
                  if (typeof el.data === "string") {
                    data_zero = el.data;
                    name_zero = el.name;
                    color_zero = el.color;
                  } else {
                    data_zero = el.data.value;
                    name_zero = el.name;
                    color_zero = el.color;
                  }
                } else {
                  if (typeof el.data === "string") {
                    data_one = el.data;
                    name_one = el.name;
                    color_one = el.color;
                  } else {
                    data_one = el.data.value;
                    name_one = el.name;
                    color_one = el.color;
                  }
                }
              });

              for (let i = 0; i < params.length; i++) {
                if (params[i]["seriesIndex"] === 0) {
                  prep_body[0] = `
                    <span style="
                      text-align:left;
                      display:inline-block;
                      margin-right:5px;
                      border-radius:10px;
                      width:10px;
                      height:10px;
                    "></span>
                    <span>
                    <span style="
                      display:inline-block;
                      margin-right:5px;
                      border-radius:10px;
                      width:10px;
                      height:10px;
                      background-color:${color_zero};
                      "></span>${params[0].axisValue}:
                      <br>&emsp;&emsp;
                        &#8702; ${data_zero} ${name_zero}
                    </span> <br />`;
                }
                if (params[i]["seriesIndex"] === 1) {
                  prep_body[1] = `
                    <span style="
                      text-align:left;
                      display:inline-block;
                      margin-right:5px;
                      border-radius:10px;
                      width:10px;
                      height:10px;
                    "></span>
                    <span>
                    <span style="
                      display:inline-block;
                      margin-right:5px;
                      border-radius:10px;
                      width:10px;
                      height:10px;
                      background-color:${color_one};
                      "></span>${params[1].axisValue}:
                      <br>&emsp;&emsp;
                        &#8702; ${data_one} ${name_one}
                    </span> <br />`;
                }
              }
              for (let key in Object.keys(prep_body).sort()) {
                body += prep_body[key];
              }
              footer += `</div>`;
            }
            return header + body.replace("undefined", "") + footer;
          },
        },
        grid: [
          {
            left: rs_digits === 3 ? 52 : 60,
            right: 50,
            height: "22%",
            // bottom: "65%",
            containLabel: true,
          },
          {
            left: us_digits === 3 ? 55 : us_digits === 2 ? 60 : 68,
            right: 50,
            top: "55%",
            bottom: "15%",
            height: "30%",
            containLabel: true,
          },
        ],
        xAxis: [
          {
            gridIndex: 0,
            type: "category",
            data: monthlyData.map((el: { shipment: any }) => el.shipment),
            axisTick: {
              alignWithLabel: true,
              show: false,
            },
            axisLabel: {
              show: false,
            },
            show: true,
          },
          {
            gridIndex: 1,
            type: "category",
            data: monthlyData.map((el: { shipment: any }) => el.shipment),
            axisLabel: {
              textStyle: {
                color: this.$vuetify.theme.dark ? "#fff" : "#000",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "R$/sc",
            position: "left",
            nameLocation: "middle",
            nameGap: 30,
            gridIndex: 0,
            nameTextStyle: {
              color: this.$vuetify.theme.dark ? "#fff" : "#000",
              fontWeight: "bolder",
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: this.$vuetify.theme.dark ? "#fff" : "#000",
              },
            },
            splitLine: {
              show: false,
            },
            splitNumber: 4,
            max: max_rs_yaxes,
            min: min_rs_yaxes,
          },
          {
            type: "value",
            name: "U$/sc",
            position: "left",
            gridIndex: 1,
            nameLocation: "middle",
            nameGap: 30,
            nameTextStyle: {
              color: this.$vuetify.theme.dark ? "#fff" : "#000",
              fontWeight: "bolder",
            },
            axisLine: {
              // show: true,
              lineStyle: {
                color: this.$vuetify.theme.dark ? "#fff" : "#000",
              },
            },
            splitLine: {
              show: false,
            },
            splitNumber: 4,
            max: max_us_yaxes,
            min: min_us_yaxes,
          },
        ],
        toolbox: {
          show: true,
          right: "10%",
          feature: {
            saveAsImage: {
              show: true,
              title: "Salvar",
              iconStyle: {
                color: "rgba(255,255,255,0)",
                borderColor: "green",
                borderWidth: 2,
              },
            },
          },
        },
        series: [
          {
            name: "R$/sc",
            type: "bar",
            xAxisIndex: 0,
            yAxisIndex: 0,
            barWidth: "60%",
            label: {
              show: true,
              position: "top",
              color: this.$vuetify.theme.dark ? "#fff" : "#000",
              fontStyle: "bold",
            },
            data: monthlyData.map((el: { sc_rs_price: number }) => {
              if (min_rs === el.sc_rs_price && !has_rs_min) {
                has_rs_min = true;
                return {
                  value: el.sc_rs_price.toFixed(2),
                  is_min: true,
                  itemStyle: {
                    color: "#990000",
                  },
                };
              } else if (max_rs === el.sc_rs_price && !has_rs_max) {
                has_rs_max = true;
                return {
                  value: el.sc_rs_price.toFixed(2),
                  is_max: true,
                  itemStyle: {
                    color: "#2C3F7A",
                  },
                };
              } else {
                return el.sc_rs_price.toFixed(2);
              }
            }),
          },
          {
            name: "U$/sc",
            type: "bar",
            xAxisIndex: 1,
            yAxisIndex: 1,
            label: {
              show: true,
              position: "top",
              color: this.$vuetify.theme.dark ? "#fff" : "#000",
              fontStyle: "bold",
            },
            barWidth: "60%",
            data: monthlyData.map((el: { sc_us_price: number }) => {
              if (min_us === el.sc_us_price && !has_us_min) {
                has_us_min = true;
                return {
                  value: el.sc_us_price.toFixed(2),
                  itemStyle: {
                    color: "#990000",
                  },
                };
              } else if (max_us === el.sc_us_price && !has_us_max) {
                has_us_max = true;
                return {
                  value: el.sc_us_price.toFixed(2),
                  itemStyle: {
                    color: "#2C3F7A",
                  },
                };
              } else {
                return el.sc_us_price.toFixed(2);
              }
            }),
          },
        ],
      };
      this.getWatermark();
    },
    getWatermark() {
      let canvas = document.createElement("canvas");
      let chartWidth = 500;
      let chartHeight = 500;
      let divCanvas = document.getElementById("basisChartWeekly");
      if (divCanvas) {
        chartWidth = divCanvas.offsetWidth;
        chartHeight = divCanvas.offsetHeight;
      }
      canvas.setAttribute("width", chartWidth.toString());
      canvas.setAttribute("height", chartHeight.toString());
      let ctx = canvas.getContext("2d");
      var img = new Image();
      if (ctx) ctx.globalAlpha = 0.5;
      img.addEventListener(
        "load",
        function () {
          if (ctx) {
            ctx.drawImage(img, chartWidth * 0.41, chartHeight * 0.45, 140, 40);
            ctx.globalCompositeOperation = "lighter";
          }
        },
        false
      );
      img.src = logoImage;
      if (this.options) {
        this.options.backgroundColor = {
          image: canvas,
          repeat: "no-repeat",
        };
      }
    },
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {},
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    "$vuetify.theme.dark"() {
      this.generateChart();
      this.getWatermark();
    },
    windowHeight() {
      this.generateChart();
      this.getWatermark();
    },
    windowWidth() {
      this.generateChart();
      this.getWatermark();
    },
    yearSelected() {
      this.generateChart();
      this.getWatermark();
    },
  },
});
