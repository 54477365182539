
import Vue, { VueConstructor } from "vue";

import BasisMonthlyHistoryChart from "../charts/BasisMonthlyHistoryChart.vue";
import BasisMonthyHistoryTable from "../tables/BasisMonthlyHistoryTable.vue";

import Growing from "@/models/growing";
import Ownership from "@/models/ownership";
import City from "@/models/city";

import { BasisMonthlyHistory } from "@/business/HistoryBasis";
import { mapGetters, mapActions } from "vuex";
import { basisService } from "@/services/basis";
import { IBasisHistoryParams } from "@/types/service-types";
import { QuotationsDate } from "@/business/Quotations";

interface IChartComponent {
  generateChart?: () => void;
}

type RangeDates = {
  [key: number]: string[];
};

export default (Vue as VueConstructor<Vue>).extend({
  props: {
    selectedGrowing: Growing,
    ownerSelected: Ownership,
    basisCity: String,
    basisAsset: String,
    shipmentsQuotes: QuotationsDate,
  },
  components: {
    MonthTable: BasisMonthyHistoryTable,
    MonthChart: BasisMonthlyHistoryChart,
  },

  data() {
    return {
      // Historico mensal para projeção
      basisMonthlyHistory: new BasisMonthlyHistory(),
      // controla o skelleton
      loading: false,
      // Controla msg de erro
      error: false,
      // Monstra no grafico
      errorMessage: "",
      crudSkelletonAttrs: {
        class: "mb-6",
        elevation: 0,
      },
      yearSelected: new Date().getMonth() == 11 ? new Date(new Date().getFullYear()+1, 0 , 1).getFullYear() : new Date().getFullYear(),
      datesRange: {} as RangeDates,
    };
  },

  methods: {
    ...mapActions({
      myOwnerships: "MY_OWNERSHIPS",
    }),
    async loadHistoryTableChart(): Promise<void> {
      this.loading = true;
      await this.getBasisMonthlyHistory();
      this.loading = false;
      this.$nextTick(() => {
        this.chartGeneration();
      });
    },
    // GET BASIS DATA
    async getBasisMonthlyHistory(): Promise<void> {
      this.error = false;
      try {
        if (this.selectedGrowing?.symbol && this.basisCity) {
          let params: IBasisHistoryParams = {
            growing: this.selectedGrowing?.symbol,
            city: this.basisCity.split(" - ")[0],
            state: this.basisCity.split(" - ")[1],
            period: "monthly",
            se: this.basisAsset.toUpperCase(),
          };

          this.basisMonthlyHistory = await basisService.getBasisMonthlyHistory(
            params
          );
        } else {
          let missing = [];
          if (!this.selectedGrowing) missing.push("Cultura");
          if (!this.basisCity) {
            missing.push("'Cidade Referência'");
            let ownerCity = this.cities.find(
              (c: City) => this.ownerSelected?.city_id == c.id
            );
            let ownerRefCity = this.cities.find(
              (c: City) => this.ownerSelected?.city_reference_id == c.id
            );
            if (this.selectedGrowing && ownerCity && ownerRefCity) {
              ownerCity = ownerCity.name;
              ownerRefCity = ownerRefCity.name;
              this.errorMessage = `Não há dados historicos para ${this.selectedGrowing.name} na cidade ${ownerCity}/${ownerRefCity}`;
            } else {
              this.errorMessage = "Nenhuma Cultura/Propriedade encontra";
            }
          }
          throw new Error("missing args " + missing.join(" ,"));
        }
      } catch (error) {
        console.error(error);
        this.error = true;
      }
    },

    chartGeneration(): void {
      const execChart = this.$refs[
        "BasisMonthlyHistoryChart"
      ] as IChartComponent;
      if (execChart && execChart.generateChart) {
        execChart.generateChart();
      }
    },
    startLoadComp(): void {
      this.loading = true;
    },
    genDates(): void {
      // get todays date
      let todays = new Date().getMonth() == 11 ? new Date(new Date().getFullYear()+1, 0 , 1) : new Date();
      let sum_months = 36;
      // force date be the first day of month
      let todaysDate = new Date(
        todays.getFullYear().toString() +
          "-" +
          (todays.getMonth() + 1).toString() +
          "-" +
          "1"
      )
        .toISOString()
        .split("T")[0];

      let endDate = this.$luxon(todaysDate)
        .plus({ months: sum_months })
        .toString()
        .substr(0, 10);

      let aux = todays.getFullYear();
      this.datesRange[aux] = [];

      while (todaysDate < endDate) {
        if (parseInt(todaysDate.split("-")[0]) !== aux) {
          aux = parseInt(todaysDate.split("-")[0]);
          this.datesRange[aux] = [];
        }
        this.datesRange[aux].push(
          this.$luxon(todaysDate).toString().substr(0, 10)
        );
        todaysDate = this.$luxon(todaysDate)
          .plus({ months: 1 })
          .toString()
          .substr(0, 10);
      }
    },
    setYear(item: number): void {
      this.yearSelected = item;
      // this.chartGeneration();
    },

    setDatesWithQuotes(){
      let dates_with_quotes = {} as RangeDates
      let se_dates: string|string[] = []
      if (this.basisAsset === 'cbot') {
        se_dates = this.shipmentsQuotes.se.cbot.reduce((selected_dates: any, el: any) => {
          if (el.value !== 0) {
            selected_dates.push(el.date)
          }
          return selected_dates
        },[])
      }
      if (this.basisAsset === 'b3') {
        se_dates = this.shipmentsQuotes.se.b3.reduce((selected_dates: any, el: any) => {
          if (el.value !== 0) {
            selected_dates.push(el.date)
          }
          return selected_dates
        },[])
      }

      let years = Object.keys(this.datesRange);

      years.forEach((year: string) => {
        this.datesRange[parseInt(year)].forEach((date: string) => {
          if (se_dates.includes(date)) {
            // eslint-disable-next-line no-prototype-builtins
            if (!dates_with_quotes.hasOwnProperty(parseInt(year)))
              dates_with_quotes[parseInt(year)] = []
            dates_with_quotes[parseInt(year)].push(date)
          }
        })
      })

      this.datesRange = dates_with_quotes
    }
  },

  computed: {
    ...mapGetters({
      growings: "getGrowings",
      cities: "getCities",
    }),
    setBtns(): number[] {
      this.genDates();
      this.setDatesWithQuotes();

      let yearsBtn: number[] = [];
      Object.keys(this.datesRange).forEach((key: string) => {
        yearsBtn.push(parseInt(key));
      });
      return yearsBtn;
    },
  },
  watch: {
    selectedGrowing() {
      this.setYear(new Date().getMonth() == 11 ? new Date(new Date().getFullYear()+1, 0 , 1).getFullYear() : new Date().getFullYear());
    },
    basisAsset() {
      this.setYear(new Date().getMonth() == 11 ? new Date(new Date().getFullYear()+1, 0 , 1).getFullYear() : new Date().getFullYear());
    },
  },
});
