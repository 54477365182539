
import Vue from "vue";

import Growing from "@/models/growing";
import Currency from "@/models/currency";
import BasisShipment from "@/models/basisShipment";
import constants from "@/constants";
import Business from "@/utilsBusiness";

import { mapGetters } from "vuex";
import {
  BasisWeeklyHistory,
  IBasisChartOptions,
} from "@/business/HistoryBasis";
import { QuotationsDate, Quotes } from "@/business/Quotations";
import { getMonthName } from "@/utilsDate";
import { getMinYaxis, getMaxYaxis } from "@/utilsText";
import logoImage from "@/assets/img/agrinvest_logo.png";
import User from "@/models/user";

type SackPrice = {
  rs: number;
  us: number;
};

export default Vue.extend({
  props: {
    growingSelected: Growing,
    basisCity: String,
    basisShipments: Array as () => BasisShipment[],
    basisWeeklyHistory: BasisWeeklyHistory,
    basisAsset: String,
    loading: Boolean,
    quotesDates: QuotationsDate,
    userLogged: User,
  },

  data: () => ({
    options: undefined as IBasisChartOptions | undefined,
    currency: undefined as Currency | undefined,
    monthsNames: getMonthName(),
    crudSkelletonAttrs: {
      class: "mb-6",
      elevation: 0,
    },
    colorsLine: [
      "#066F6A", // green
      "#D1AE6C", // gold
      "#B24C63", // pink
      "#6D8299", // blue
      "#FD7F63", // pink
      "#435E55", // dark green
      "#2C3F7A", // blue
    ],
    // colorsLine: ["#0000FF", "#00FF00", "#FF0000"],
    colorsScatter: [
      "#0E262F", // dark blue
      "#56876D", // green
      "#FD5200", // red
      "#F7A278", // pink
      "#F7C548", // yellow
      "#A3A3A3", // gray
      "#F2A65A" // orange
    ],
    minYaxis: 0,
    maxYaxis: 100,
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
  }),

  methods: {
    generateChart(): void {
      let yearColors = {} as any;
      let five_years_history = [] as any;
      let five_years_ago = [] as any;
      let four_years_ago = [] as any;
      let three_years_ago = [] as any;
      let two_years_ago = [] as any;
      let one_years_ago = [] as any;
      let year_to_date_history = [] as any;
      yearColors = this.createColors();

      let title =
        this.basisCity.toUpperCase() + " " + this.basisAsset.toUpperCase();

      let year_five_ago = (new Date().getFullYear() - 5).toString();
      let year_four_ago = (new Date().getFullYear() - 4).toString();
      let year_three_ago = (new Date().getFullYear() - 3).toString();
      let year_two_ago = (new Date().getFullYear() - 2).toString();
      let year_one_ago = (new Date().getFullYear() - 1).toString();
      let ytd = (new Date().getFullYear()).toString();

      let legendLine = [
        "line: Histórico",
        "line: " + year_five_ago,
        "line: " + year_four_ago,
        "line: " + year_three_ago,
        "line: " + year_two_ago,
        "line: " + year_one_ago,
        "line: " + ytd,
      ];
      let colorLegend: { [key: string]: string } = {
        "Histórico": this.colorsLine[0],
        [year_five_ago]: this.colorsLine[1],
        [year_four_ago]: this.colorsLine[2],
        [year_three_ago]: this.colorsLine[3],
        [year_two_ago]: this.colorsLine[4],
        [year_one_ago]: this.colorsLine[5],
        [ytd]: this.colorsLine[6],
      }

      let legendScatter = Object.entries(yearColors).map((key) => {
        return "scatter: " + key[0];
      });

      five_years_history = this.basisWeeklyHistory.five_years_history.map((el) => el.value);
      five_years_history.push(five_years_history[five_years_history.length - 1]);

      five_years_ago = this.basisWeeklyHistory.five_years_ago.map((el) => el.value);
      five_years_ago.push(five_years_ago[five_years_ago.length - 1]);

      four_years_ago = this.basisWeeklyHistory.four_years_ago.map((el) => el.value);
      four_years_ago.push(four_years_ago[four_years_ago.length - 1]);

      three_years_ago = this.basisWeeklyHistory.three_years_ago.map((el) => el.value);
      three_years_ago.push(three_years_ago[three_years_ago.length - 1]);

      two_years_ago = this.basisWeeklyHistory.two_years_ago.map((el) => el.value);
      two_years_ago.push(two_years_ago[two_years_ago.length - 1]);

      one_years_ago = this.basisWeeklyHistory.one_years_ago.map((el) => el.value);
      one_years_ago.push(one_years_ago[one_years_ago.length - 1]);

      year_to_date_history = this.basisWeeklyHistory.year_to_date_history.map((el) => el.value);
      year_to_date_history.push(year_to_date_history[year_to_date_history.length - 1]);

      const min_five_history = Math.min.apply(null, five_years_history) || 0;
      const min_five = Math.min.apply(null, five_years_ago) || 0;
      const min_four = Math.min.apply(null, four_years_ago) || 0;
      const min_three = Math.min.apply(null, three_years_ago) || 0;
      const min_two = Math.min.apply(null, two_years_ago) || 0;
      const min_one = Math.min.apply(null, one_years_ago) || 0;
      const min_ytd_history = Math.min.apply(null, year_to_date_history) || 0;

      const max_five_history = Math.max.apply(null, five_years_history) || 0;
      const max_five = Math.max.apply(null, five_years_ago) || 0;
      const max_four = Math.max.apply(null, four_years_ago) || 0;
      const max_three = Math.max.apply(null, three_years_ago) || 0;
      const max_two = Math.max.apply(null, two_years_ago) || 0;
      const max_one = Math.max.apply(null, one_years_ago) || 0;
      const max_ytd_history = Math.max.apply(null, year_to_date_history) || 0;

      const basis_values = this.calcAssets();
      const minYval =
        Math.min.apply(
          null,
          basis_values.concat([
            min_five_history, min_five, min_four,
            min_three, min_two, min_one, min_ytd_history
        ])
        ) || 0;
      const maxYval =
        Math.max.apply(
          null,
          basis_values.concat([
            max_five_history, max_five, max_four,
            max_three, max_two, max_one, max_ytd_history
          ])
        ) || 0;
      this.minYaxis = getMinYaxis(minYval);
      this.maxYaxis = getMaxYaxis(maxYval);

      this.options = {
        color: this.colorsLine,
        title: {
          left: "center",
          text: title,
          textStyle: {
            color: this.$vuetify.theme.dark ? "#fff" : "#000",
            fontSize: 15,
          },
        },
        axisPointer: {
          link: {
            xAxisIndex: "all",
          },
          label: {
            backgroundColor: "#777",
          },
        },
        legend: [
          {
            data: legendLine.map((el) => el.toString()),
            bottom: 15,
            left: "center",
            textStyle: {
              color: this.$vuetify.theme.dark ? "#fff" : "#000",
            },
            formatter: (name: string) => {
              return name.split(" ")[1];
            },
          },
          {
            data: legendScatter.map((el) => el.toString()),
            bottom: -4,
            left: "center",
            textStyle: {
              color: this.$vuetify.theme.dark ? "#fff" : "#000",
            },
            formatter: (name: string) => {
              return name.split(" ")[1];
            },
          },
        ],
        tooltip: {
          trigger: "axis",
          position: "top",
          axisPointer: {
            type: "cross",
          },
          extraCssText: "width: 170px",
          formatter: (params: any) => {
            let tooltipMsg = "";
            if (params.length) {
              tooltipMsg = `
                <span style="text-align:center;"> Basis: </span> <br />
                <div class="chart-tooltip">
              `;
              for (let i = 0; i < params.length; i++) {
                if (Array.isArray(params[i].value)) {
                  tooltipMsg += `
                    <span style="
                      text-align:left;
                      display:inline-block;
                      margin-right:5px;
                      border-radius:10px;
                      width:10px;
                      height:10px;
                      background-color:${
                        yearColors[params[i].seriesName.split(" ")[1]]
                      };
                    "></span>
                    <span>
                      ${params[i].seriesName.split(" ")[1]}:
                      <br>&emsp;&emsp;
                        &#8702; ${this.$luxon(params[i].data.myLegend)
                          .setLocale("pt-BR")
                          .toFormat("dd LLL yyyy")}
                      <br>&emsp;&emsp;
                        &#8702; ${params[i].value[1]}
                        ${this.basisAsset === "cbot" ? "¢/bu" : "R$/sc"}
                    </span> <br />`;
                } else {
                  tooltipMsg += `
                    <span style="
                      text-align:left;
                      display:inline-block;
                      margin-right:5px;
                      border-radius:10px;
                      width:10px;
                      height:10px;
                      background-color:${colorLegend[params[i].seriesName.split(" ")[1]]};
                    "></span>
                    <span>
                      ${params[i].seriesName.split(" ")[1]}:
                      ${params[i].value}
                      ${this.basisAsset === "cbot" ? "¢/bu" : "R$/sc"}
                    </span><br />`;
                }
              }
              tooltipMsg += `</div>`;
            }
            return tooltipMsg;
          },
        },
        grid: [
          {
            left: "10%",
            right: "2%",
            bottom: "20%",
            top: "20%",
            height: "60%",
          },
        ],
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: this.basisWeeklyHistory.five_years_history.map((el) => el.date),
            show: true,
            axisLabel: {
              interval: 0,
              show: false,
            },
            axisPointer: {
              label: {
                formatter: (params: any) => {
                  return params.value
                    ? this.$luxon(params.value)
                        .setLocale("br")
                        .toFormat("LLL-yyyy")
                    : "";
                },
              },
            },
          },
          {
            axisPointer: false,
            position: "bottom",
            type: "category",
            data: this.monthsNames,
            xAxisIndex: 2,
            axisLabel: {
              textStyle: {
                color: this.$vuetify.theme.dark ? "#fff" : "#000",
              },
              padding: [0, 0, 25, 0],
            },
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: this.basisAsset === "cbot" ? "¢/bu" : "R$/sc",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              color: this.$vuetify.theme.dark ? "#fff" : "#000",
              fontWeight: "bolder",
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: this.$vuetify.theme.dark ? "#fff" : "#000",
              },
            },
            boundaryGap: [0, "100%"],
            axisLabel: {
              textStyle: {
                color: this.$vuetify.theme.dark ? "#fff" : "#000",
              },
            },
            axisTick: {
              interval: 0,
              textStyle: {
                color: this.$vuetify.theme.dark ? "#fff" : "#000",
              },
            },
            splitNumber: 8,
            scale: true,
            min: this.minYaxis,
            max: this.maxYaxis,
          },
        ],
        toolbox: {
          show: true,
          right: "10%",
          feature: {
            saveAsImage: {
              show: true,
              title: "Salvar",
              iconStyle: {
                color: "rgba(255,255,255,0)",
                borderColor: "green",
                borderWidth: 2,
              },
            },
          },
        },
        series: [
          {
            name: legendLine[0].toString(),
            type: "line",
            smooth: true,
            data: five_years_history,
            lineStyle: {
              width: 2,
              type: "solid",
            },
            symbol: "circle",
            symbolSize: 10,
          },
          {
            name: legendLine[1].toString(),
            type: "line",
            smooth: true,
            data: five_years_ago,
            lineStyle: {
              width: 2,
              type: "dashed",
            },
            symbol: "circle",
            symbolSize: 10,
          },
          {
            name: legendLine[2].toString(),
            type: "line",
            smooth: true,
            data: four_years_ago,
            lineStyle: {
              width: 2,
              type: "dashed",
            },
            symbol: "circle",
            symbolSize: 10,
          },
          {
            name: legendLine[3].toString(),
            type: "line",
            smooth: true,
            data: three_years_ago,
            lineStyle: {
              width: 2,
              type: "dashed",
            },
            symbol: "circle",
            symbolSize: 10,
          },
          {
            name: legendLine[4].toString(),
            type: "line",
            smooth: true,
            data: two_years_ago,
            lineStyle: {
              width: 2,
              type: "dashed",
            },
            symbol: "circle",
            symbolSize: 10,
          },
          {
            name: legendLine[5].toString(),
            type: "line",
            smooth: true,
            data: one_years_ago,
            lineStyle: {
              width: 2,
              type: "dashed",
            },
            symbol: "circle",
            symbolSize: 10,
          },
          {
            name: legendLine[6].toString(),
            type: "line",
            smooth: true,
            data: year_to_date_history,
            lineStyle: {
              width: 2,
              type: "dashed",
            },
            symbol: "circle",
            symbolSize: 10,
          },
        ],
      };
      this.getWatermark();
      this.addScatter();
    },
    calcAssets(): Array<number> {
      let dollar = 0;
      let se = 0;
      let seArray = new Quotes();
      let basis: number[] = [];
      this.basisShipments.map((el) => {
        const dArray = this.quotesDates.dollar.filter((dollar) => {
          if (dollar.date) {
            if (
              el.payment_date.split("-").slice(0, 2).join("-") ===
              dollar.date.split("-").slice(0, 2).join("-")
            ) {
              return dollar;
            }
          }
        })[0];
        if (dArray) dollar = parseFloat(dArray.value.toFixed(3));

        if (this.basisAsset === "cbot") {
          seArray = this.quotesDates.se.cbot.filter((se) => {
            if (se.date) {
              if (
                el.shipment_date.split("-").slice(0, 2).join("-") ===
                se.date.split("-").slice(0, 2).join("-")
              ) {
                return se;
              }
            }
          })[0];
        }
        if (this.basisAsset === "b3") {
          seArray = Business.b3QuoteByDate(el.shipment_date, this.quotesDates.se.b3) || new Quotes;
        }

        if (seArray) se = parseFloat(seArray.value.toFixed(2));

        const sc_price = this.setValue(el.currency_id, el.value, dollar);
        basis.push(this.calcBasis(sc_price, se));

      });
      return basis;
    },

    addScatter(): void {
      if (this.basisShipments.length && this.options) {
        let yearColors = {} as any;
        yearColors = this.createColors();

        let scatterData: any = {};
        let years: any[] = [];
        let dollar = 0;
        let se = 0;
        let seArray = new Quotes();

        // Get array of years
        this.basisShipments.filter((el) => {
          years.push(this.$luxon(el.shipment_date).year);
          return null;
        });
        years = Array.from(new Set(years)).sort();

        // Create an object for each basisShipments based on its year
        years.forEach((year) => {
          if (
            this.options?.color &&
            !this.options?.color.includes(yearColors[year])
          ) {
            this.options?.color.push(yearColors[year]);
          }

          scatterData[year] = [];
          this.basisShipments.map((el) => {
            if (this.$luxon(el.shipment_date).year === year) {
              const dArray = this.quotesDates.dollar.filter((dollar) => {
                if (dollar.date) {
                  if (
                    el.payment_date.split("-").slice(0, 2).join("-") ===
                    dollar.date.split("-").slice(0, 2).join("-")
                  ) {
                    return dollar;
                  }
                }
              })[0];
              if (dArray) dollar = parseFloat(dArray.value.toFixed(3));

              if (this.basisAsset === "cbot") {
                seArray = this.quotesDates.se.cbot.filter((se) => {
                  if (se.date) {
                    if (
                      el.shipment_date.split("-").slice(0, 2).join("-") ===
                      se.date.split("-").slice(0, 2).join("-")
                    ) {
                      return se;
                    }
                  }
                })[0];
              }
              if (this.basisAsset === "b3") {
                seArray = Business.b3QuoteByDate(el.shipment_date, this.quotesDates.se.b3) || new Quotes;
              }

              if (seArray) se = parseFloat(seArray.value.toFixed(2));

              const sc_price = this.setValue(el.currency_id, el.value, dollar);
              const basis = this.calcBasis(sc_price, se);
              const scatterValue = this.timeDiffCalc(el.shipment_date);

              scatterData[year].push({
                value: [scatterValue === 52 ? 51 : scatterValue, basis],
                myLegend: el.shipment_date,
                label: {
                  show: true,
                  formatter: function (param: any) {
                    return param.data[3];
                  },
                  position: "inside",
                  minMargin: 2,
                },
                itemStyle: {
                  color: yearColors[year],
                  opacity: 0.8,
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowOffsetY: 0,
                  shadowColor: "rgba(0,0,0,0.3)",
                },
              });
            }
          });
        });

        // iterate over years again to align colors and legends
        years.forEach((year) => {
          Object.entries(scatterData).map(([key, value]) => {
            if (year == key) {
              this.options?.series.push({
                name: "scatter: " + key,
                type: "scatter",
                data: value,
                symbol: "circle",
                symbolSize: 30,
                label: {
                  fontWeight: "bold",
                },
              });
            }
          });
        });
      }
    },
    createColors(): void {
      let today = new Date().getFullYear();
      let yearColors = {} as any;
      for (let i = 0; i < 8; i++) {
        let key = (today + i).toString();
        yearColors[key] = this.colorsScatter[i];
      }
      return yearColors;
    },
    timeDiffCalc(shipment_date: string) {
      let diffInMilliseconds = 0;
      let todaysYear = new Date().getFullYear().toString();
      let finalDate = "";

      // convert date to this year like history data
      let payDay = new Date(
        todaysYear +
          "/" +
          shipment_date.split("-").slice(-2).join("/") +
          "/" +
          " 00:00:00"
      );

      // find the nearest week number of basis history data
      this.basisWeeklyHistory.five_years_history.some((el) => {
        let hisDay = new Date(el.date + " 00:00:00");
        diffInMilliseconds = Math.abs(+payDay - +hisDay) / 1000;
        const days = Math.floor(diffInMilliseconds / 86400);
        if (days >= 0 && days <= 7) {
          finalDate = el.date;
          return;
        }
      });
      return this.$luxon(finalDate).weekNumber;
    },
    setValue(currency_id: number, value: number, dollar: number): SackPrice {
      this.currency = this.currencies.filter(
        (c: Currency) => c.id === currency_id
      )[0];

      let result = { rs: 0, us: 0 };

      if (this.currency?.identifier === "R") {
        result.rs = value;
        result.us = value / dollar;
      }
      if (this.currency?.identifier === "D") {
        result.rs = value * dollar;
        result.us = value;
      }
      return result;
    },
    calcBasis(sc_price: SackPrice, se: number): number {
      let basis = 0;
      if (this.basisAsset == "cbot") {
        let constant = constants.get(this.growingSelected.symbol);
        if (constant) {
          basis = (sc_price.us / constant.x) * 100 - se;
        }
      } else {
        basis = sc_price.rs - se;
      }
      return parseInt(basis.toFixed(0));
    },
    getWatermark() {
      let canvas = document.createElement("canvas");
      let chartWidth = 500;
      let chartHeight = 500;
      let divCanvas = document.getElementById("basisChartWeekly");
      if (divCanvas) {
        chartWidth = divCanvas.offsetWidth;
        chartHeight = divCanvas.offsetHeight;
      }
      canvas.setAttribute("width", chartWidth.toString());
      canvas.setAttribute("height", chartHeight.toString());
      let ctx = canvas.getContext("2d");
      var img = new Image();
      if (ctx) ctx.globalAlpha = 0.5;
      img.onload = function () {
        if (ctx) {
          ctx.drawImage(img, chartWidth * 0.41, chartHeight * 0.45, 140, 40);
        }
      };
      img.src = logoImage;
      if (this.options) {
        this.options.backgroundColor = {
          image: canvas,
          repeat: "no-repeat",
        };
      }
    },
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {
    ...mapGetters({
      currencies: "getCurrencies",
    }),
    currenciesAvailable(): Array<Currency> {
      return this.currencies.filter(
        (c: Currency) => c.identifier === "R" || c.identifier === "D"
      );
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    "$vuetify.theme.dark"() {
      this.generateChart();
      this.getWatermark();
    },
    windowHeight() {
      this.generateChart();
      this.getWatermark();
    },
    windowWidth() {
      this.generateChart();
      this.getWatermark();
    },
    basisShipments(): void {
      this.generateChart();
      this.getWatermark();
    },
  },
});
