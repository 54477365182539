
import Vue, { VueConstructor } from "vue";

import BasisHistoryChart from "../charts/BasisHistoryChart.vue";

import Growing from "@/models/growing";
import Ownership from "@/models/ownership";
import City from "@/models/city";

import { BasisMonthlyHistory } from "@/business/HistoryBasis";
import { mapGetters, mapActions } from "vuex";
import { basisService } from "@/services/basis";
import { IBasisHistoryParams } from "@/types/service-types";

interface IChartComponent {
  generateChart?: () => void;
}

export default (Vue as VueConstructor<Vue>).extend({
  props: {
    selectedGrowing: Growing,
    ownerSelected: Ownership,
    basisCity: String,
    basisAsset: String,
  },
  components: {
    BasisChart: BasisHistoryChart,
  },

  data() {
    return {
      justify: [
        'end',
      ],
      // Historico mensal para projeção
      basisHistoryData: new BasisMonthlyHistory(),
      // controla o skelleton
      loading: false,
      // Controla msg de erro
      error: false,
      // Monstra no grafico
      errorMessage: "",
      // data inicial
      startDate: new Date(2017, 0, 1).toISOString().split('T')[0],
      // data final
      endDate: new Date().toISOString().split('T')[0],
      // minimo inicial
      minStartDate: new Date(2017, 0, 1).toISOString().split('T')[0],
      // maximo inicial
      maxStartDate: new Date(new Date().setDate(new Date().getDate()-1)).toISOString().split('T')[0],
      // minimo final
      minEndDate: new Date(2017, 0, 2).toISOString().split('T')[0],
      // maximo final
      maxEndDate: new Date().toISOString().split('T')[0],
      crudSkelletonAttrs: {
        class: "mb-6",
        elevation: 0,
      },
    };
  },

  methods: {
    ...mapActions({
      myOwnerships: "MY_OWNERSHIPS",
    }),
    async loadHistoryTableChart(): Promise<void> {
      this.loading = true;
      await this.getBasisHistory();
      this.loading = false;
      this.$nextTick(() => {
        this.chartGeneration();
      });
    },
    // GET BASIS DATA
    async getBasisHistory(): Promise<void> {
      this.error = false;
      try {
        if (this.selectedGrowing?.symbol && this.basisCity) {
          let params: IBasisHistoryParams = {
            growing: this.selectedGrowing?.symbol,
            city: this.basisCity.split(" - ")[0],
            state: this.basisCity.split(" - ")[1],
            start_date: this.startDate,
            end_date: this.endDate,
            period: "basis",
            se: this.basisAsset.toUpperCase(),
          };

          this.basisHistoryData = await basisService.getBasisHistory(
            params
          );
        } else {
          let missing = [];
          if (!this.selectedGrowing) missing.push("Cultura");
          if (!this.basisCity) {
            missing.push("'Cidade Referência'");
            let ownerCity = this.cities.find(
              (c: City) => this.ownerSelected?.city_id == c.id
            );
            let ownerRefCity = this.cities.find(
              (c: City) => this.ownerSelected?.city_reference_id == c.id
            );
            if (this.selectedGrowing && ownerCity && ownerRefCity) {
              ownerCity = ownerCity.name;
              ownerRefCity = ownerRefCity.name;
              this.errorMessage = `Não há dados historicos para ${this.selectedGrowing.name} na cidade ${ownerCity}/${ownerRefCity}`;
            } else {
              this.errorMessage = "Nenhuma Cultura/Propriedade encontra";
            }
          }
          throw new Error("missing args " + missing.join(" ,"));
        }
      } catch (error) {
        console.error(error);
        this.error = true;
      }
    },

    chartGeneration(): void {
      const execChart = this.$refs[
        "BasisHistoryChart"
      ] as IChartComponent;
      if (execChart && execChart.generateChart) {
        execChart.generateChart();
      }
    },
    startLoadComp(): void {
      this.loading = true;
    },
    async findResults(): Promise<void> {
      await this.loadHistoryTableChart()
    }
  },


  computed: {
    ...mapGetters({
      growings: "getGrowings",
      cities: "getCities",
    }),
    basisHistoryChartData(): BasisMonthlyHistory {
      return this.basisHistoryData;
    },
  },
  watch: {

  },
});
