
import Vue, { VueConstructor } from "vue";

import Currency from "@/models/currency";
import Growing from "@/models/growing";
import Ownership from "@/models/ownership";
import constants from "@/constants";

import { QuotationsDate, Quotes } from "@/business/Quotations";
import {
  BasisMonthlyHistory,
  DisplayBasisMonthlyHistory,
} from "@/business/HistoryBasis";

type SackPrice = {
  rs: number;
  us: number;
};

export default (Vue as VueConstructor<Vue>).extend({
  props: {
    growingSelected: Growing,
    ownershipSelected: Ownership,
    basisMonthlyHistory: BasisMonthlyHistory,
    quotesDates: QuotationsDate,
    basisAsset: String,
    loading: Boolean,
    datesRange: Object,
    yearSelected: Number,
  },
  data: () => ({
    currency: undefined as Currency | undefined,
    show: false,
    headers: [
      {
        text: "Embarque",
        tooltip: "Data de embarque",
        align: "left",
        value: "shipment",
        sortable: false,
      },
      {
        text: "Físico (R$)",
        tooltip: "Valor do embarque",
        align: "center",
        value: "sc_rs_price",
        sortable: false,
      },
      {
        text: "Físico (U$)",
        tooltip: "Valor do embarque",
        align: "center",
        value: "sc_us_price",
        sortable: false,
      },
      {
        text: "CBOT",
        tooltip: "(¢/bu)",
        align: "center",
        value: "se_value",
        sortable: false,
      },
      {
        text: "Dólar",
        tooltip: "(R$)",
        align: "center",
        value: "dollar",
        sortable: false,
      },
      {
        text: "Basis",
        tooltip: "(¢/bu)",
        align: "center",
        value: "basis",
        sortable: false,
      },
    ],
    crudSkelletonAttrs: {
      class: "mb-6",
      elevation: 0,
    },
    crudClass:
      "font-weight-bold font-weight-black caption text-md-h5 text-sm-subtitle-1",
  }),
  methods: {
    calcSackPrice(dollar: number, se: number, basis: number): SackPrice {
      let rs_sc = 0;
      let us_sc = 0;

      let constant = constants.get(this.growingSelected.symbol);

      if (this.basisAsset === "cbot") {
        if (constant) {
          rs_sc = ((basis + se) * constant.x * dollar) / 100;
          us_sc = ((basis + se) * constant.x) / 100;
        }
      } else {
        rs_sc = basis + se;
        us_sc = (basis + se) / dollar;
      }
      return {
        rs: rs_sc,
        us: us_sc,
      } as SackPrice;
    },
  },

  computed: {
    monthlyHistoryItems(): DisplayBasisMonthlyHistory[] {
      let basis = 0;
      let se = 0;
      let dollar = 0;
      let code_se = "";
      let code_dollar = "";
      let sc_price = {} as SackPrice;

      let seArray = new Quotes();
      let dates = this.datesRange[this.yearSelected];
      let datesAvailable = dates ? [...dates] : [];
      if (this.yearSelected === new Date().getFullYear()) {
        datesAvailable.shift();
      }

      return datesAvailable.map((el) => {
        let dateCompare = this.$luxon(el)
          .toString()
          .split("-")
          .slice(0, 2)
          .join("-");

        // add a month in each obj
        const dArray = this.quotesDates.dollar.filter((dollar, idx) => {
          if (dollar.date) {
            // add a month in each obj
            let quoteDate = this.$luxon(dollar.date)
              .toString()
              .split("-")
              .slice(0, 2)
              .join("-");

            if (quoteDate === dateCompare) {
              return this.quotesDates.dollar[idx + 1];
            }
          }
        })[0];
        if (dArray) {
          dollar = dArray.value;
          code_dollar = dArray.code;
        }

        if (this.basisAsset === "cbot") {
          seArray = this.quotesDates.se.cbot.filter((se, idx) => {
            if (se.date) {
              let quoteDate = this.$luxon(se.date)
                .toString()
                .split("-")
                .slice(0, 2)
                .join("-");
              if (quoteDate === dateCompare) {
                return this.quotesDates.se.cbot[idx + 1];
              }
            }
          })[0];
        }
        if (this.basisAsset === "b3") {
          seArray = this.quotesDates.se.b3.filter((se, idx) => {
            if (se.date) {
              let quoteDate = this.$luxon(se.date)
                .toString()
                .split("-")
                .slice(0, 2)
                .join("-");
              if (quoteDate === dateCompare) {
                return this.quotesDates.se.b3[idx + 1];
              }
            }
          })[0];
        }
        if (seArray) {
          se = seArray.value;
          code_se = seArray.code;
        }
        const basisArray = this.basisMonthlyHistory.history.filter((basis) => {
          // add month cz getMonth starts from 0;
          let compareBasis = new Date(el).getMonth() + 1;

          if (compareBasis === parseInt(basis.date)) {
            return basis;
          }
        })[0];
        if (basisArray) basis = basisArray.value;

        sc_price = this.calcSackPrice(dollar, se, basis);
        const month = this.$luxon(el).monthShort.replace(".", "");
        const year = this.$luxon(el).toFormat("yy");
        const _date =
          month.charAt(0).toUpperCase() + month.slice(1) + "-" + year;
        return {
          shipment: _date,
          sc_rs_price: sc_price.rs,
          sc_us_price: sc_price.us,
          se_value: parseFloat(se.toFixed(2)),
          dollar: parseFloat(dollar.toFixed(3)),
          code_se: code_se,
          code_dollar: code_dollar,
          basis: basis,
        } as DisplayBasisMonthlyHistory;
      });
    },
  },
});
