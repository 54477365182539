
import Vue, { VueConstructor } from "vue";

import BasisShipment, { DisplayBasisShipment } from "@/models/basisShipment";
import Currency from "@/models/currency";
import Growing from "@/models/growing";
import Ownership from "@/models/ownership";
import constants from "@/constants";
import Business from "@/utilsBusiness";

import { mapGetters } from "vuex";
import { QuotationsDate, Quotes } from "@/business/Quotations";
import { BasisWeeklyHistory } from "@/business/HistoryBasis";
import User from "@/models/user";

type SackPrice = {
  rs: number;
  us: number;
};

export default (Vue as VueConstructor<Vue>).extend({
  props: {
    basisShipments: Array as () => BasisShipment[],
    growingSelected: Growing,
    ownershipSelected: Ownership,
    basisWeeklyHistory: BasisWeeklyHistory,
    quotesDates: QuotationsDate,
    basisAsset: String,
    loading: Boolean,
    userLogged: User,
  },
  data: () => ({
    currency: undefined as Currency | undefined,
    show: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 6,
    headers: [
      {
        text: "Embarque",
        tooltip: "Data de embarque",
        align: "left",
        value: "date",
        sortable: false,
      },
      {
        text: "Físico (R$)",
        tooltip: "Valor do embarque",
        align: "center",
        value: "sc_rs_price",
        sortable: false,
      },
      {
        text: "Físico (U$)",
        tooltip: "Valor do embarque",
        align: "center",
        value: "sc_us_price",
        sortable: false,
      },
      {
        text: "CBOT",
        tooltip: "(¢/bu)",
        align: "center",
        value: "se_value",
        sortable: false,
      },
      {
        text: "Dólar",
        tooltip: "(R$)",
        align: "center",
        value: "dollar",
        sortable: false,
      },
      {
        text: "Basis",
        tooltip: "(¢/bu)",
        align: "center",
        value: "basis",
        sortable: false,
      },
      {
        text: "Dif. Histórico",
        tooltip: "(¢/bu)",
        align: "center",
        value: "dif_history",
        sortable: false,
      },
      {
        text: "Ações",
        tooltip: "Alterações no embarque",
        align: "center",
        value: "actions",
        sortable: false,
      },
    ],
    crudSkelletonAttrs: {
      class: "mb-6",
      elevation: 0,
    },
    crudClass:
      "font-weight-bold font-weight-black caption text-md-h5 text-sm-subtitle-1",
    btest: new BasisShipment(),
  }),
  methods: {
    checker(): boolean {
      if (this.basisShipments.length === 0) return true;
      else return false;
    },
    setValue(currency_id: number, value: number, dollar: number): SackPrice {
      this.currency = this.currencies.filter(
        (c: Currency) => c.id === currency_id
      )[0];

      const result = { rs: 0, us: 0 };
      if (this.currency?.identifier === "R") {
        result.rs = value;
        result.us = value / dollar;
      }
      if (this.currency?.identifier === "D") {
        result.rs = value * dollar;
        result.us = value;
      }
      return result;
    },
    calcBasis(sc_price: SackPrice, se: number) {
      let basis = 0;
      if (this.basisAsset == "cbot") {
        const constant = constants.get(this.growingSelected.symbol);
        if (constant) {
          basis = (sc_price.us / constant.x) * 100 - se;
        }
      } else {
        basis = sc_price.rs - se;
      }
      return basis;
    },

    calcDifBasis(shipment_date: string, se: number, dollar: number): SackPrice {
      let diffInMilliseconds = 0;
      const todaysYear = new Date().getFullYear().toString();
      const result = { rs: 0, us: 0 };

      // convert date to this year like history data
      const payDay = new Date(
        todaysYear +
          "/" +
          shipment_date.split("-").slice(-2).join("/") +
          "/" +
          " 00:00:00"
      );

      // find the nearest date and return value
      this.basisWeeklyHistory.five_years_history.some((el) => {
        const hisDay = new Date(el.date + " 00:00:00");
        diffInMilliseconds = Math.abs(+payDay - +hisDay) / 1000;
        const days = Math.floor(diffInMilliseconds / 86400);
        if (days >= 0 && days <= 7) {
          const constant = constants.get(this.growingSelected.symbol);
          if (this.basisAsset == "cbot") {
            if (constant) {
              result.rs = (((el.value + se) * constant.x) / 100) * dollar;
              result.us = ((el.value + se) * constant.x) / 100;
            }
          } else {
            if (constant) {
              result.rs = el.value + se;
              result.us = (el.value + se) / dollar;
            }
          }
        }
      });
      return result;
    },
  },
  computed: {
    ...mapGetters({
      currencies: "getCurrencies",
    }),
    currenciesAvailable(): Array<Currency> {
      return this.currencies.filter(
        (c: Currency) => c.identifier === "R" || c.identifier === "D"
      );
    },
    shipmentItems(): DisplayBasisShipment[] {
      if (this.basisShipments) {
        return this.basisShipments.map((el) => {
          let basis = 0;
          let se = 0;
          let dollar = 0;
          let code_se = "";
          let code_dollar = "";
          let seArray = new Quotes();

          const dArray = this.quotesDates.dollar.filter((dollar) => {
            if (dollar.date) {
              if (
                el.payment_date.split("-").slice(0, 2).join("-") ===
                dollar.date.split("-").slice(0, 2).join("-")
              ) {
                return dollar;
              }
            }
          })[0];
          if (dArray) {
            dollar = parseFloat(dArray.value.toFixed(3));
            code_dollar = dArray.code;
          }

          if (this.basisAsset === "cbot") {
            seArray = this.quotesDates.se.cbot.filter((se) => {
              if (se.date) {
                if (
                  el.shipment_date.split("-").slice(0, 2).join("-") ===
                  se.date.split("-").slice(0, 2).join("-")
                ) {
                  return se;
                }
              }
            })[0];
          }
          if (this.basisAsset === "b3") {
            seArray = Business.b3QuoteByDate(el.shipment_date, this.quotesDates.se.b3) || new Quotes;
          }
          if (seArray) {
            se = parseFloat(seArray.value.toFixed(2));
            code_se = seArray.code;
          }

          const sc_price = this.setValue(el.currency_id, el.value, dollar);
          const sc_rs_price = sc_price.rs;
          const sc_us_price = sc_price.us;

          basis = this.calcBasis(sc_price, se);

          const historic_price = this.calcDifBasis(
            el.shipment_date,
            se,
            dollar
          );
          const historic_price_rs = historic_price.rs;
          const historic_price_us = historic_price.us;

          const month = this.$luxon(el.shipment_date).monthShort.replace(
            ".",
            ""
          );
          const year = this.$luxon(el.shipment_date).toFormat("yy");
          const _date =
            month.charAt(0).toUpperCase() + month.slice(1) + "-" + year;

          return new DisplayBasisShipment(
            el,
            _date,
            sc_rs_price,
            sc_us_price,
            se,
            dollar,
            basis,
            code_dollar,
            code_se,
            historic_price_rs,
            historic_price_us
          );
        });
      }
      return [];
    },
  },
});
