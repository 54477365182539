import axios from "@/plugins/axios";

import { BasisWeeklyHistory, BasisCities, BasisMonthlyHistory } from "@/business/HistoryBasis";
import { IBasisHistoryParams } from '@/types/service-types';
import { intanciateApiData, isValidResponse } from "@/utilsObject";

const getBasisWeeklyHistory = async (params: IBasisHistoryParams): Promise<BasisWeeklyHistory> => {
  const response = await axios.httpApi.get<BasisWeeklyHistory>("charts/basis-history", { params });
  if (isValidResponse(response.status)) {
    return intanciateApiData(BasisWeeklyHistory, response.data);
  }
  console.error(`Error to get history basis: ${response.statusText}`);
  return response.data;
};

const getBasisMonthlyHistory = async (params: IBasisHistoryParams): Promise<BasisMonthlyHistory> => {
  const response = await axios.httpApi.get<BasisMonthlyHistory>("charts/basis-history", { params });
  if (isValidResponse(response.status)) {
    return intanciateApiData(BasisMonthlyHistory, response.data);
  }
  console.error(`Error to get history basis: ${response.statusText}`);
  return response.data;
};

const getBasisHistory = async (params: IBasisHistoryParams): Promise<BasisMonthlyHistory> => {
  const response = await axios.httpApi.get<BasisMonthlyHistory>("charts/basis-history", { params });
  if (isValidResponse(response.status)) {
    return intanciateApiData(BasisMonthlyHistory, response.data);
  }
  console.error(`Error to get history basis: ${response.statusText}`);
  return response.data;
};

const getCitiesBasis = async (): Promise<BasisCities> => {
  const response = await axios.httpApi.get("charts/basis-exw-cities");
  if (!isValidResponse(response.status))
    console.error(`Error to get history basis: ${response.statusText}`);
  return response.data;
};

export const basisService = {
  getBasisWeeklyHistory,
  getBasisMonthlyHistory,
  getBasisHistory,
  getCitiesBasis
};
