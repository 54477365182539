import { isEmptyObject, stringToNumber } from "@/utilsObject";

export interface IBasisAsset {
  date: string;
  value: number;
}

export class BasisAssetDTO implements IBasisAsset {
  date = "";
  value = 0;

  constructor(dto?: IBasisAsset) {
    if (dto && !isEmptyObject(dto)) {
      this.date = dto.date;
      this.value = stringToNumber(dto.value);
    }
  }
}

export class BasisAsset extends BasisAssetDTO {
  constructor();
  constructor(asset: IBasisAsset);
  constructor(asset?: IBasisAsset) {
    super(asset);
  }
}

export interface IBasisWeeklyHistory {
  five_years_history: Array<BasisAsset>;
  five_years_ago: Array<BasisAsset>;
  four_years_ago: Array<BasisAsset>;
  three_years_ago: Array<BasisAsset>;
  two_years_ago: Array<BasisAsset>;
  one_years_ago: Array<BasisAsset>;
  year_to_date_history: Array<BasisAsset>;
  city: string;
  state: string;
  growing: string;
}

export class BasisWeeklyHistoryDTO implements IBasisWeeklyHistory {
  five_years_history: Array<BasisAsset> = [];
  five_years_ago: Array<BasisAsset> = [];
  four_years_ago: Array<BasisAsset> = [];
  three_years_ago: Array<BasisAsset> = [];
  two_years_ago: Array<BasisAsset> = [];
  one_years_ago: Array<BasisAsset> = [];
  year_to_date_history: Array<BasisAsset> = [];
  city!: string | '';
  state!: string | '';
  growing!: string | '';

  constructor(dto?: IBasisWeeklyHistory) {
    if (dto && !isEmptyObject(dto)) {
      this.five_years_history = dto.five_years_history.map((el) => new BasisAsset(el));
      this.five_years_ago = dto.five_years_ago.map((el) => new BasisAsset(el));
      this.four_years_ago = dto.four_years_ago.map((el) => new BasisAsset(el));
      this.three_years_ago = dto.three_years_ago.map((el) => new BasisAsset(el));
      this.two_years_ago = dto.two_years_ago.map((el) => new BasisAsset(el));
      this.one_years_ago = dto.one_years_ago.map((el) => new BasisAsset(el));
      this.year_to_date_history = dto.year_to_date_history.map((el) => new BasisAsset(el));
      this.city = dto.city;
      this.state = dto.state;
      this.growing = dto.growing;
    }
  }
}

export class BasisWeeklyHistory extends BasisWeeklyHistoryDTO {
  constructor();
  constructor(basisWeeklyHistory: IBasisWeeklyHistory);
  constructor(basisWeeklyHistory?: IBasisWeeklyHistory) {
    super(basisWeeklyHistory);
  }
}

export interface IBasisMonthlyHistory {
  history: Array<BasisAsset>;
  city: string;
  state: string;
  growing: string;
  se: string;
}

export class BasisMonthlyHistoryDTO implements IBasisMonthlyHistory {
  history: Array<BasisAsset> = [];
  city!: string | '';
  state!: string | '';
  growing!: string | '';
  se!: string | '';

  constructor(dto?: IBasisMonthlyHistory) {
    if (dto && !isEmptyObject(dto)) {
      this.history = dto.history.map((el) => new BasisAsset(el));
      this.city = dto.city;
      this.state = dto.state;
      this.growing = dto.growing;
      this.se = dto.se;
    }
  }
}

export class BasisMonthlyHistory extends BasisMonthlyHistoryDTO {
  constructor();
  constructor(basisMonthlyHistory: IBasisMonthlyHistory);
  constructor(basisMonthlyHistory?: IBasisMonthlyHistory) {
    super(basisMonthlyHistory);
  }
}

export interface IDisplayBasisMonthlyHistory {
  shipment: string;
  sc_rs_price: number;
  sc_us_price: number;
  se_value: number;
  dollar: number;
  code_se: string;
  code_dollar: string;
  basis: number;
}

export class DisplayBasisMonthlyHistoryDTO implements IDisplayBasisMonthlyHistory {
  shipment = '';
  sc_rs_price = 0;
  sc_us_price = 0;
  se_value = 0;
  dollar = 0;
  code_se = '';
  code_dollar = '';
  basis = 0;

  constructor(dto?: DisplayBasisMonthlyHistoryDTO) {
    if (dto) {
      this.shipment = dto.shipment;
      this.sc_rs_price = dto.sc_rs_price;
      this.sc_us_price = dto.sc_us_price;
      this.se_value = dto.se_value;
      this.dollar = dto.dollar;
      this.code_se = dto.code_se;
      this.code_dollar = dto.code_dollar;
      this.basis = dto.basis;
    }
  }
}

export class DisplayBasisMonthlyHistory extends DisplayBasisMonthlyHistoryDTO {
  constructor();
  constructor(displayBasisMonthlyHistory: IDisplayBasisMonthlyHistory);
  constructor(displayBasisMonthlyHistory?: IDisplayBasisMonthlyHistory) {
    super(displayBasisMonthlyHistory);
  }
}

export interface IBasisCities {
  soybean?: Array<string>;
  corn?: Array<string>;
}

export class BasisCitiesDTO implements IBasisCities {
  soybean: Array<string> | undefined = undefined;
  corn: Array<string> | undefined = undefined;

  constructor(dto?: IBasisCities) {
    if (dto && !isEmptyObject(dto)) {
      this.soybean = dto?.soybean;
      this.corn = dto.corn
    }
  }
}

export class BasisCities extends BasisCitiesDTO {
  constructor();
  constructor(cities: IBasisCities);
  constructor(cities?: IBasisCities) {
    super(cities);
  }
}

export interface IScatterData {
  value: [number, number],
  label: {
    show: boolean,
    formatter: (param: any) => string,
    position: string,
    minMargin: number
  },
  itemStyle: {
    color: string,
    opacity: number,
    shadowBlur: number,
    shadowOffsetX: number,
    shadowOffsetY: number,
    shadowColor: string
  },
}

export interface IBasisChartOptions {
  backgroundColor?: {
    type?: string,
    image?: HTMLCanvasElement,
    repeat?: string,
  },
  color?: string[],
  title?: {
    left?: string,
    top?: string | number,
    text?: string,
    textStyle?: {
      color?: string,
      fontSize?: number;
    },
    subtext?: string,
    subtextStyle?: {
      color?: string,
    },
  },
  axisPointer?: {
    link?: {
      xAxisIndex?: string,
    } | any,
    label?: {
      backgroundColor?: string,
    },
  },
  legend?: {
    data?: string[],
    bottom?: number,
    left?: string,
    top?: string,
    textStyle?: {
      color?: string,
      width?: string
    },
    formatter?: any,
  } | any,
  dataZoom?: [
    {
      type?: string,
      start?: number,
      end?: number,
    },
    {
      start?: number,
      end?: number,
      handleIcon?:
        string,
      handleSize?: string,
      handleStyle?: {
        color?: string,
        shadowBlur?: number,
        shadowColor?: string,
        shadowOffsetX?: number,
        shadowOffsetY?: number,
      },
    },
  ],
  tooltip?: {
    trigger?: string,
    axisPointer?: {
      type?: string,
      axis?: string
    },
    position?: any,
    extraCssText?: string,
    formatter?: any,
  },
  grid?: {
    left?: string | number,
    right?: string | number,
    bottom?: string | number,
    top?: string | number,
    height?: string | number,
    containLabel?: boolean
  }[],
  xAxis?: {
    type?: string,
    boundaryGap?: boolean | Array<any>,
    data?: string[] | Array<any>,
    show?: boolean,
    position?: string,
    offset?: number,
    axisLabel?: {
      interval?: number,
      show?: boolean,
      textStyle?: {
        color?: string,
      },
      padding?: number[],
    },
    axisTick?: {
      show?: boolean,
      alignWithLabel?: boolean,
    },
    axisPointer?: boolean | {
      label?: {
        formatter?: any
      },
    },
    gridIndex?: number,
    xAxisIndex?: number,
  }[],
  yAxis?: {
    type?: string,
    gridIndex?: number,
    yAxisIndex?: number,
    boundaryGap?: [number, string],
    name?: string,
    nameLocation?: string,
    nameGap?: number,
    position?: string,
    offset?: number,
    nameTextStyle?: {
      color?: string,
      fontWeight?: string
    },
    axisLine?: {
      show?: boolean,
      onZero?: boolean,
      lineStyle?: {
        color?: string,
      },
    },
    splitLine?: any,
    splitNumber?: number,
    data?: Array<any>,
    scale?: boolean,
    axisTick?: {
      alignWithLabel?: boolean,
      interval?: number,
      inside?: boolean,
      length?: number,
      show?: false,
      lineStyle?: {
        color?: any,
        width?: any,
        type?: any,
        dashOffset?: any,
        cap?: any,
        join?: any,
        miterLimit?: any,
        shadowBlur?: any,
        shadowColor?: any,
        shadowOffsetX?: any,
        shadowOffsetY?: any,
        opacity?: any
      },
      textStyle?: {
        color?: string,
      },
    },
    axisLabel?: {
      interval?: number,
      textStyle?: {
        color?: string,
      },
      formatter?: any,
      fontSize?: number,
    },
    max?: number,
    min?: number,
  }[],
  toolbox?: {
    show?: boolean,
    right?: string,
    feature?: {
      magicType?: {
        show?: boolean,
        type?: string[],
        title?: {
          line?: string,
          bar?: string,
        },
        icon?: any,
        iconStyle?: {
          color?: string,
          borderColor?: string,
          borderWidth?: number
        }[] | any,
      },
      restore?: {
        show?: boolean,
        title?: string,
        iconStyle?: {
          color?: string,
          borderColor?: string,
          borderWidth?: number
        },
      },
      saveAsImage?: {
        show?: boolean,
        title?: string,
        iconStyle?: {
          color?: string,
          borderColor?: string,
          borderWidth?: number
        },
      },
    },
  },
  series?: {
    name?: string,
    type?: string,
    smooth?: boolean,
    data?: any,
    tooltip?: boolean,
    yAxisIndex?: number,
    itemStyle?: {
      color?: string,
      opacity?: number,
      shadowBlur?: number,
      shadowOffsetX?: number,
      shadowOffsetY?: number,
      shadowColor?: string
    },
    lineStyle?: {
      width?: number,
      type?: string,
    },
    label?: {
      show?: boolean,
      formatter?: any,
      position?: string,
      minMargin?: number
    }
    symbol?: string,
    symbolSize?: number,
  }[] | any,
}
