
import Vue, { VueConstructor } from "vue";

import BasisWeeklyHistory from "./main/BasisWeeklyHistory.vue";
import BasisMonthlyHistory from "./main/BasisMonthlyHistory.vue";
import BasisShipmentForm from "./forms/BasisShipmentForm.vue";
import BasisHistory from "./main/BasisHistory.vue";

import CrudMixins from "@/mixins/crud";
import BasisMixins from "@/mixins/basisAnalysis";
import Growing from "@/models/growing";
import Ownership from "@/models/ownership";
import City from "@/models/city";
import BasisShipment from "@/models/basisShipment";

import { BasisCities } from "@/business/HistoryBasis";
import { IFormFuncs } from "@/types/form-types";
// import { mapGetters, mapActions } from "vuex";
import { basisService } from "@/services/basis";
import { IParams, ISearchResponse } from "@/types/store-types";
// import { crudService } from "@/services/crud";
import { IQuotationsDate, QuotationsDate } from "@/business/Quotations";

import { utilsService } from "@/services/utils";
import { crudService } from "@/services/crud";

interface IBasisWeeklyHistoryComponent {
  loadHistoryTableChart?: () => void;
  startLoadComp?: () => void;
  chartGeneration?: () => void;
}

interface IBasisMonthlyHistoryComponent {
  loadHistoryTableChart?: () => void;
  startLoadComp?: () => void;
  chartGeneration?: () => void;
}

interface IBasisHistoryComponent {
  loadHistoryTableChart?: () => void;
  startLoadComp?: () => void;
  chartGeneration?: () => void;
}

interface IQuotationParams {
  growing: string;
  items: {
    dollar: Array<string>;
    se: {
      cbot: Array<string>;
      b3?: Array<string>;
    };
  };
}

export default (
  Vue as VueConstructor<
    Vue & InstanceType<typeof CrudMixins> & InstanceType<typeof BasisMixins>
  >
).extend({
  components: {
    BasisWeeklyHistory: BasisWeeklyHistory,
    BasisMonthlyHistory: BasisMonthlyHistory,
    BasisHistory: BasisHistory,
    Form: BasisShipmentForm,
  },
  mixins: [BasisMixins],

  data() {
    return {
      // open form
      dialog: false,
      // Lista de Ownership
      ownerships: [] as Array<Ownership>,
      // Class com as cidades que temos basis
      basisCities: new BasisCities(),
      // Milho: muda a bolsa
      basisAsset: "cbot" as string,
      // Lista de embarques
      basisShipmentDisplay: [] as Array<BasisShipment>,
      // Lista com cotações da data de embarque
      shipmentsQuotes: new QuotationsDate(),
      // Cultura selecionada
      selectedGrowing: undefined as Growing | undefined,
      // Prop selecionada
      ownerSelected: undefined as Ownership | undefined,
      // Lista de Em
      results: {} as ISearchResponse<BasisShipment>,
      // Controle progress linear load
      loading: true,
      localLoading: false,
      citySelected: "Sorriso - MT" as string | undefined,
    };
  },

  sockets: {
    connect(): void {
      console.log("socket connected");
      if (this.$socket.connected) {
        this.$socket.client.emit("join_basis");
      }
    },
    room_joined(data): void {
      console.log(`user has joined the room: ${data}`);
    },
    update_quotes(): void {
      const params = this.quotationParam();
      this.$socket.client.emit("update_basis_quotes", params);
    },
    basis_quote_result(data): void {
      if (data) {
        const jsonData = JSON.parse(data);
        this.socketUpdateQuotes(jsonData);
      }
    },
  },

  // MOUNTED
  async mounted() {
    if (this.$socket.disconnected) {
      this.$socket.client.open();
    }
    this.loading = true;
    await this.loadOwnershipsAndGrowing();
    this.basisCities = await basisService.getCitiesBasis();
    if (this.user.roles.includes("admin")) {
      this.buildBasisShipments();
    } else {
      this.searchBasisShipments();
    }
    await this.getShipmentQuotes();
    this.loading = false;
    this.$nextTick(() => {
      this.basisWeeklyHistoryLoad();
      this.basisMonthlyHistoryLoad();
      this.basisHistoryLoad();
    });
  },

  methods: {
    // MAIN FUNCTION
    async execute(): Promise<void> {
      this.basisAsset = "cbot";
      this.startLoadInComponents();
      if (this.user.roles.includes("admin")) {
        this.buildBasisShipments();
      } else {
        this.searchBasisShipments();
      }
      await this.getShipmentQuotes();
      this.$nextTick(() => {
        this.basisWeeklyHistoryLoad();
        this.basisMonthlyHistoryLoad();
        this.basisHistoryLoad();
      });
    },

    // GET USER'S DATA
    async searchBasisShipments(): Promise<void> {
      const params: IParams = {
        ownership_id: this.ownerSelected?.id,
        growing_id: this.selectedGrowing?.id,
        checkDelete: true,
      };
      try {
        await this.getBasisShipments(params);
        this.basisShipmentDisplay = this.basisShipments;
      } catch (error) {
        this.$alertError("Erro ao carregar os embarques");
        console.log(error);
      } finally {
        this.localLoading = false;
      }
    },

    buildBasisShipments(): void {
      this.basisShipmentDisplay = this.basisShipments
        .map((basisShipment: BasisShipment) => {
          if (basisShipment) {
            if (
              basisShipment.growing_id === this.selectedGrowing?.id &&
              basisShipment.created_at === this.basisCity
            ) {
              return new BasisShipment(basisShipment);
            }
          }
        })
        .filter(Boolean);
    },

    quotationParam(): IQuotationParams {
      const dates = this.genDate();
      let params: IQuotationParams | undefined = undefined;

      if (this.selectedGrowing?.isCorn) {
        params = {
          growing: "corn",
          items: { dollar: dates, se: { cbot: dates, b3: dates } },
        };
      } else {
        params = {
          growing: "soybean",
          items: { dollar: dates, se: { cbot: dates } },
        };
      }
      return params;
    },

    async getShipmentQuotes(): Promise<void> {
      if (this.selectedGrowing) {
        const params = this.quotationParam();
        try {
          const response = await utilsService.getQuotationsByArrayOfDate(
            params
          );
          if (response) {
            this.shipmentsQuotes = response;
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        console.warn("getShipmentQuotes: no growing selected");
      }
    },

    genDate(): Array<string> {
      let todaysYear = new Date().getFullYear();
      let todayDate = new Date(todaysYear + "-1" + "-1")
        .toISOString()
        .split("T")[0];

      let dates: string[] = [];
      for (let month = 0; month < 48; month++) {
        dates.push(
          this.$luxon(todayDate)
            .plus({ months: month })
            .toString()
            .substr(0, 10)
        );
      }

      return dates;
    },
    openForm(): void {
      const form = this.$refs["BasisShipmentForm"] as IFormFuncs<BasisShipment>;
      if (form) {
        this.dialog = true;
      } else {
        this.$alertError("Erro ao abrir formulário");
      }
    },

    edit(uBasisShipment: BasisShipment): void {
      const form = this.$refs["BasisShipmentForm"] as IFormFuncs<BasisShipment>;
      if (form && form.toUpdate) {
        form.toUpdate(new BasisShipment(uBasisShipment));
      }
    },

    removeFromResourceList(item: BasisShipment): void {
      this.$store.commit("removeBasisShipment", item.id);
    },
    async confirmDelete(item: BasisShipment): Promise<void> {
      try {
        if (this.user.roles.includes("admin")) {
          await this.deleteBasisShipmentsStore(item.id);
          this.buildBasisShipments();

          const BasisWeeklyHistoryComp = this.$refs[
            "BasisWeeklyHistoryComp"
          ] as IBasisWeeklyHistoryComponent;
          if (BasisWeeklyHistoryComp.chartGeneration) {
            BasisWeeklyHistoryComp.chartGeneration();
          }
        } else {
          if (item.id) {
            this.setResource("sale_trigger");
            await crudService.deleteClass(
              BasisShipment,
              BasisShipment.getResource(),
              item.id
            );

            await this.deleteBasisShipmentsStore(item.id);
            this.removeFromResourceList(item);
            this.$alertSuccess("Embarque deletedo com sucesso!");
            this.removeFromResourceList(item);

            const BasisWeeklyHistoryComp = this.$refs[
              "BasisWeeklyHistoryComp"
            ] as IBasisWeeklyHistoryComponent;
            if (BasisWeeklyHistoryComp.chartGeneration)
              BasisWeeklyHistoryComp.chartGeneration();
          } else {
            this.$alertError("Algo não previsto aconteceu!");
            console.error("confirmDelete: BasisShipment without id!");
          }
        }
      } catch (error) {
        this.$alertError("Houve um problema ao deletar o embarque!");
        console.error(error);
      }
    },
    async loadOwnershipsAndGrowing(): Promise<void> {
      this.selectedGrowing = this.growings.find(
        (g: Growing) => g.symbol === "S"
      );
      this.ownerships = await this.myOwnerships();

      if (this.ownerships) {
        this.ownerSelected = this.ownerships[0];
      }
    },
    async basisWeeklyHistoryLoad(): Promise<void> {
      const BasisWeeklyHistoryComp = this.$refs[
        "BasisWeeklyHistoryComp"
      ] as IBasisWeeklyHistoryComponent;
      if (BasisWeeklyHistoryComp.loadHistoryTableChart) {
        await BasisWeeklyHistoryComp.loadHistoryTableChart();
      }
    },
    async basisMonthlyHistoryLoad(): Promise<void> {
      const BasisMonthlyHistoryComp = this.$refs[
        "BasisMonthlyHistoryComp"
      ] as IBasisMonthlyHistoryComponent;
      if (BasisMonthlyHistoryComp.loadHistoryTableChart) {
        await BasisMonthlyHistoryComp.loadHistoryTableChart();
      }
    },
    async basisHistoryLoad(): Promise<void> {
      const BasisHistoryComp = this.$refs[
        "BasisHistoryComp"
      ] as IBasisHistoryComponent;
      if (BasisHistoryComp.loadHistoryTableChart) {
        await BasisHistoryComp.loadHistoryTableChart();
      }
    },
    startLoadInComponents(): void {
      const BasisWeeklyHistoryComp = this.$refs[
        "BasisWeeklyHistoryComp"
      ] as IBasisWeeklyHistoryComponent;
      if (BasisWeeklyHistoryComp.startLoadComp)
        BasisWeeklyHistoryComp.startLoadComp();

      const BasisMonthlyHistoryComp = this.$refs[
        "BasisMonthlyHistoryComp"
      ] as IBasisMonthlyHistoryComponent;
      if (BasisMonthlyHistoryComp.startLoadComp)
        BasisMonthlyHistoryComp.startLoadComp();

      const BasisHistoryComp = this.$refs[
        "BasisHistoryComp"
      ] as IBasisHistoryComponent;
      if (BasisHistoryComp.startLoadComp)
        BasisHistoryComp.startLoadComp();
    },
    async changeStockExchange(value: string): Promise<void> {
      this.loading = true;
      this.basisAsset = value;
      this.loading = false;
      this.$nextTick(() => {
        this.basisWeeklyHistoryLoad();
        this.basisMonthlyHistoryLoad();
        this.basisHistoryLoad();
      });
    },
    socketUpdateQuotes(jsonData: IQuotationsDate): void {
      this.shipmentsQuotes = new QuotationsDate(jsonData);
      this.$nextTick(() => {
        this.basisWeeklyHistoryLoad();
        this.basisMonthlyHistoryLoad();
      });
    },
  },

  computed: {
    growingsAvailable(): Array<Growing> {
      return this.growings.filter(
        (el: Growing) => el.symbol == "S" || el.symbol == "C"
      );
    },
    cityReference(): string | undefined {
      if (this.ownerSelected) {
        const city = this.cities.find((c: City) => {
          if (c.id === this.ownerSelected?.city_reference_id) {
            return c;
          } else if (c.id === this.ownerSelected?.city_id) {
            return c;
          }
        });
        if (city) return city.name + " - " + city.state.abbreviation;
      }
      return undefined;
    },
    basisCity: {
      get(): string | undefined {
        if (this.user.roles.includes("admin")) {
          return this.citySelected;
        } else {
          if (this.cityReference) {
            if (this.selectedGrowing?.isSoybean) {
              if (this.basisCities?.soybean) {
                return (
                  this.basisCities.soybean.find(
                    (el) => el === this.cityReference
                  ) || undefined
                );
              }
            } else {
              if (this.basisCities?.corn) {
                return (
                  this.basisCities.corn.find(
                    (el) => el === this.cityReference
                  ) || undefined
                );
              }
            }
          }
        }

        return undefined;
      },
      set(city: string): void {
        this.citySelected = city;
      },
    },
    availableBasisCities(): string[] | undefined {
      if (this.selectedGrowing?.isSoybean) {
        if (this.basisCities?.soybean) {
          return this.basisCities.soybean;
        }
      } else {
        if (this.basisCities?.corn) {
          return this.basisCities.corn;
        }
      }
      return undefined;
    },
  },

  beforeDestroy() {
    if (this.$socket.connected) {
      this.$socket.client.emit("leave_basis");
      console.log("user left the room");
      this.$socket.client.close();
    }
  },
});
