
import Vue, { VueConstructor } from "vue";
import FormMixins from "@/mixins/form";
import BasisMixins from "@/mixins/basisAnalysis";
import BasisShipment from "@/models/basisShipment";

import { mapGetters } from "vuex";

import Ownership from "@/models/ownership";
import Growing from "@/models/growing";
import Currency from "@/models/currency";
import User from "@/models/user";

export default (
  Vue as VueConstructor<
    Vue & InstanceType<typeof FormMixins> & InstanceType<typeof BasisMixins>
  >
).extend({
  props: {
    ownershipSelected: Ownership,
    growingSelected: Growing,
    userLogged: User,
    basisCity: String,
  },
  mixins: [FormMixins, BasisMixins],

  data: () => ({
    basisShipment: undefined as BasisShipment | undefined,
    currency: undefined as Currency | undefined,
    valid: false as boolean,
    minDate: new Date(new Date().getFullYear(), 0, 1)
      .toISOString()
      .substr(0, 10),
    maxDate: new Date(new Date().getFullYear() + 2, 11, 31)
      .toISOString()
      .substr(0, 10),
  }),

  created() {
    this.initialize();
  },

  methods: {
    save(): void {
      if (this.validate() && this.basisShipment) {
        if (this.userLogged.roles.includes("admin")) {
          this.saveInStore();
        } else {
          this.saveInBD();
        }
      }
    },
    saveInStore(): void {
      if (this.basisShipment) {
        if (this.growingSelected.id) {
          this.basisShipment.growing_id = this.growingSelected.id;
          this.basisShipment.created_at = this.basisCity;
          const msuccess = this.componentsText.mSuccess;
          const merror = this.componentsText.mError;

          this.updateBasisShipmentsStore(this.basisShipment)
            .then(() => {
              this.$alertSuccess(msuccess);
              this.close();
              this.$emit("buildBasisShipments");
            })
            .catch((error) => {
              this.$alertError(merror);
              console.error(error);
            });
        }
      } else {
        console.warn(
          `saveInBD: no id - ownership id ${this.ownershipSelected.id} - growing id ${this.growingSelected.id}`
        );
      }
    },

    saveInBD(): void {
      if (this.basisShipment) {
        if (this.ownershipSelected.id) {
          this.basisShipment.ownership_id = this.ownershipSelected.id;
        }
        if (this.growingSelected.id) {
          this.basisShipment.growing_id = this.growingSelected.id;
        }
        // Mixins
        this.basisShipment.shipment_date = this.dateISONotNull(
          this.basisShipment.shipment_date
        ).split("T")[0];
        this.basisShipment.payment_date = this.dateISONotNull(
          this.basisShipment.payment_date
        ).split("T")[0];
        if (this.currency?.id) {
          this.basisShipment.currency_id = this.currency?.id;
        }

        this.setResource("basis_shipment");
        this.saveResource(this.basisShipment)
          .then((response) => {
            this.$alertSuccess(this.componentsText.mSuccess);
            this.$emit("searchBasisShipments", response);
            this.close();
          })
          .catch(() => {
            this.$alertError(this.componentsText.mError);
          });
      }
    },

    toUpdate(item: BasisShipment) {
      this.show = true;
      this.$nextTick(() => {
        this.basisShipment = item;
      });
    },

    initialize(): void {
      this.basisShipment = new BasisShipment();
      this.basisShipment.currency_id = 1;
      this.basisShipment.shipment_date = this.$luxon(new Date().toISOString())
        .toString()
        .substr(0, 10);

      this.basisShipment.payment_date = this.$luxon(new Date().toISOString())
        .plus({ months: 1 })
        .toString()
        .substr(0, 10);
    },

    setSDate(): void {
      if (this.basisShipment) {
        let lastYear = this.basisShipment.shipment_date.split("-")[0];
        let lastMonth = this.basisShipment.shipment_date.split("-")[1];

        if (lastMonth === "12" && lastYear === this.maxDate.split("-")[0]) {
          this.basisShipment.payment_date = lastYear + "-12-31";
        } else {
          this.basisShipment.payment_date = this.$luxon(
            this.basisShipment.shipment_date
          )
            .plus({ months: 1 })
            .toString()
            .substr(0, 10);
        }
      }
    },
    updateBasisShipmentList(item: BasisShipment): void {
      this.$store.commit("changeBasisShipment", item);
    },
  },

  computed: {
    ...mapGetters({
      currencies: "getCurrencies",
    }),

    currenciesAvailable(): Array<Currency> {
      return this.currencies.filter(
        (c: Currency) => c.identifier === "R" || c.identifier === "D"
      );
    },

    componentsText() {
      if (this.basisShipment && this.basisShipment.id) {
        return {
          buttonText: "Atualizar",
          mSuccess: "Registro atualizado com sucesso!",
          mError: "Houve um problema ao atualizar o registro",
        };
      } else {
        return {
          buttonText: "Adicionar",
          mSuccess: "Registro inserido com sucesso!",
          mError: "Houve um problema ao inserir o registro",
        };
      }
    },
  },
});
