import { render, staticRenderFns } from "./BasisHistory.vue?vue&type=template&id=95c05d56&"
import script from "./BasisHistory.vue?vue&type=script&lang=ts&"
export * from "./BasisHistory.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports