
import Vue from "vue"

import Growing from "@/models/growing";

import { BasisMonthlyHistory } from "@/business/HistoryBasis";

export default Vue.extend({
  props: {
    growingSelected: Growing,
    basisAsset: String,
    basisCity: String,
    basisHistory: BasisMonthlyHistory
  },

  data: () => ({
    options: undefined as any | undefined,
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
    localError: false,
  }),

  methods: {
    generateChart(): void {
      this.options = {
        title: {
          text: 'Basis ' + this.basisCity,
          textAlign: "center",
          left: "center",
        },
        legend: {
          data: ['','Margin Limit','Stop Limit'],
          top: '10%'
        },
        tooltip: {
          trigger: "axis",
        },
        axisPointer: {
          link: {
            xAxisIndex: "all",
          },
          label: {
            backgroundColor: "#777",
          },
        },
        grid: [
          {
            left: "10%",
            right: "10%",
            bottom: "20%",
            top: "20%",
            height: "60%",
          },
        ],
        xAxis: {
          data: this.basisHistory.history.map((el) => el.date)
        },
        yAxis: {
          type: "value",
          name: this.basisAsset === "cbot" ? "¢/bu" : "R$/sc",
          axisLabel: {
            textStyle: {
              color: this.$vuetify.theme.dark ? "#fff" : "#000",
            },
            fontSize: 12,
          },
          scale: true,
          splitNumber: 5,
          boundaryGap: [0.2, 0.2],
          nameLocation: "middle",
          nameGap: 60,
          nameTextStyle: {
            color: this.$vuetify.theme.dark ? "#fff" : "#000",
            fontWeight: "bolder",
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: this.$vuetify.theme.dark ? "#fff" : "#000",
            },
          },
          axisTick: {
            interval: 0,
            textStyle: {
              color: this.$vuetify.theme.dark ? "#fff" : "#000",
            },
          },
        },
        toolbox: {
          show: true,
          right: "10%",
          feature: {
            saveAsImage: {
              show: true,
              title: "Salvar",
              iconStyle: {
                color: "rgba(255,255,255,0)",
                borderColor: "green",
                borderWidth: 2,
              },
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
          },
          {
            start: 0,
            end: 100,
            handleIcon:
              "M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
            handleSize: "80%",
            handleStyle: {
              color: "#fff",
              shadowBlur: 3,
              shadowColor: "rgb(29, 41, 81)",
              shadowOffsetX: 2,
              shadowOffsetY: 2,
            },
          },
        ],
        series: [
          {
            name: 'Basis',
            type: "line",
            data: this.basisHistory.history.map((el) => el.value),
            smooth: true,
          },
        ],
        animationDuration: 1000,

        // title: {
        //   left: "10%",
        //   text: 'Basis',
        //   textStyle: {
        //     color: this.$vuetify.theme.dark ? "#fff" : "#000",
        //     fontSize: 15,
        //   },
        // },
        // legend: {
        //   data: ['legendsChartA'],
        //   top: '10%'
        // },
        // tooltip: {
        //   trigger: 'axis',
        //   // position: (pt: any) => {
        //   //   return [pt[0], '10'];
        //   // },
        //   formatter:(params: any) => {
        //     let output = '<b>' + params[0].name + '</b><br/>'
        //     for (let i = 0; i < params.length; i++) {
        //       if (params[i].value){
        //         if (typeof params[i].value !== 'object') {
        //           output += params[i].marker + params[i].seriesName + ': ' + params[i].value;
        //           if (i != params.length - 1) { // Append a <br/> tag if not last in loop
        //             console.log(output)
        //             output += '<br/>'
        //           }
        //         }else {
        //           output += params[i].marker + params[i].seriesName + ': ' + params[i].value[1];
        //           if (i != params.length - 1) { // Append a <br/> tag if not last in loop
        //             output += '<br/>'
        //           }
        //         }
        //       }
        //     }
        //     return output
        //   }
        // },
        // axisPointer: {
        //   link: {
        //     xAxisIndex: "all",
        //   },
        //   label: {
        //     backgroundColor: "#777",
        //   },
        // },
        // grid: [
        //   {
        //     left: "10%",
        //     right: "2%",
        //     bottom: "20%",
        //     top: "20%",
        //     height: "60%",
        //   },
        // ],
        // xAxis: {
        //   type: 'category',
        //   data: this.basisHistory.history.map((el) => el.date)
        // },
        // yAxis: {
        //   type: "value",
        //   // name: "R$/sc",
        //   // splitLine: { show: false },
        //   // scale: true,
        //   // splitNumber: 5,
        //   // nameLocation: "middle",
        //   // nameGap: 60,
        //   // nameTextStyle: {
        //   //   color: this.$vuetify.theme.dark ? "#fff" : "#000",
        //   //   fontWeight: "bolder",
        //   // },
        //   // axisLine: {
        //   //   show: true,
        //   //   lineStyle: {
        //   //     color: this.$vuetify.theme.dark ? "#fff" : "#000",
        //   //   },
        //   // },
        //   // boundaryGap: [0, "100%"],
        //   // axisLabel: {
        //   //   textStyle: {
        //   //     color: this.$vuetify.theme.dark ? "#fff" : "#000",
        //   //   },
        //   //   formatter: (param: string) => {
        //   //     return "R$ " + param;
        //   //   },
        //   //   fontSize: 12,
        //   // },
        //   // axisTick: {
        //   //   interval: 0,
        //   //   textStyle: {
        //   //     color: this.$vuetify.theme.dark ? "#fff" : "#000",
        //   //   },
        //   // },
        // },
        // toolbox: {
        //   show: true,
        //   right: "10%",
        //   feature: {
        //     // dataView: {show: true, readOnly: false},
        //     magicType: {
        //       show: true,
        //       type: ["line", "bar"],
        //       title: {
        //         line: "Linhas",
        //         bar: "Barras",
        //       },
        //     },
        //     restore: {
        //       show: true,
        //       title: "Atualizar",
        //       iconStyle: {
        //         color: "rgba(255,255,255,0)",
        //         borderColor: "orange",
        //         borderWidth: 2,
        //       },
        //     },
        //     saveAsImage: {
        //       show: true,
        //       title: "Salvar",
        //       iconStyle: {
        //         color: "rgba(255,255,255,0)",
        //         borderColor: "green",
        //         borderWidth: 2,
        //       },
        //     },
        //   },
        // },
        // dataZoom: [
        //   {
        //     type: "inside",
        //     start: 0,
        //     end: 100,
        //   },
        //   {
        //     start: 0,
        //     end: 100,
        //     handleIcon:
        //       "M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
        //     handleSize: "80%",
        //     handleStyle: {
        //       color: "#fff",
        //       shadowBlur: 3,
        //       shadowColor: "rgb(29, 41, 81)",
        //       shadowOffsetX: 2,
        //       shadowOffsetY: 2,
        //     },
        //   },
        // ],
        // series: [{
        //   data: this.basisHistory.history.map((el) => el.value),
        //   type: 'line',
        //   smooth: true
        // }]
      };
    },
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {},
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    "$vuetify.theme.dark"() {
      this.generateChart();
    },
    windowHeight() {
      this.generateChart();
    },
    windowWidth() {
      this.generateChart();
    },
    monthlyProjectionDataTable(){
      this.generateChart();
    }
  },
});
